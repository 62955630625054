import { Model, model, prop } from 'mobx-keystone';

import DashiConfig from '../config';
import FirebaseConfig from '../types/FirebaseConfig';
import DashiAppStore, { IDashiAppStore } from './AppStore';
import DashiColorizerStore, { IDashiColorizerStore } from './ColorizerStore';
import DashiPersistStore, { IDashiPersistStore } from './PersistStore';
import DashiUserStore, { IDashiUserStore } from './UserStore';


export type TopLevelStores = {
    app?: IDashiAppStore;
    persist?: IDashiPersistStore;
    user?: IDashiUserStore;
    colorizer?: IDashiColorizerStore;
};

export interface IRootStore {
    app: IDashiAppStore;
    persist: IDashiPersistStore;
    user: IDashiUserStore;
    colorizer: IDashiColorizerStore;
    config: typeof DashiConfig & FirebaseConfig;
}

@model('dashi/RootStore')
class RootStore extends Model({
    config: prop<typeof DashiConfig & FirebaseConfig>(),
    persist: prop<IDashiPersistStore>(() => new DashiPersistStore({})),
    colorizer: prop<IDashiColorizerStore>(() => new DashiColorizerStore({})),
    user: prop<IDashiUserStore>(() => new DashiUserStore({})),
    app: prop<IDashiAppStore>(() => new DashiAppStore({})),

}) {}


export default RootStore;
