import React from 'react';
import { observer } from 'mobx-react';


interface RadioSliderProps<T> {
    value: T,
    options: T[],
    onChange: (value: T) => void,
}


@observer
export default class RadioSlider<T> extends React.Component<RadioSliderProps<T>> {
    render() {
        const { value, options, onChange } = this.props;
        const width = 100 / options.length;

        const highlighterStyle = {
            width: `${width}%`,
            left: `${options.indexOf(value) * width}%`,
        };

        return (
            <div className="RadioSlider">
                <div className="RadioSlider__highlighter" style={highlighterStyle} />

                {options.map((option: T) => {
                    return (
                        <div key={String(option)} className={`RadioSlider__option ${value === option ? 'RadioSlider__option--active' : ''}`} onClick={() => onChange(option)}>
                            {option}
                        </div>
                    );
                })}
            </div>
        );
    }
}
