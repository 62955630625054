import { Block } from 'react-timeline';
import { ExtendedModel, model } from 'mobx-keystone';

import DashiAppStore from '../../../core/stores/AppStore';
import data from '../assets/projects.json';

import CostMetric from '../metrics/Cost';
import SpaceTypeMetric from '../metrics/SpaceType';
import DemoProject, { IDemoProjectData } from '../models/Project';


@model('demo/AppStore')
class DemoAppStore extends ExtendedModel(DashiAppStore, {}) {

    onInit() {
        const Cost = new CostMetric({ label: 'Construction Cost' });

        const SpaceType = data.reduce((metric, project) => {
            Object.values(project.metrics.payloads).forEach(payload => {
                if (payload.spaceType && metric.options.indexOf(payload.spaceType) === -1) {
                    metric.addOption(payload.spaceType);
                }
            });
            
            return metric;
        }, new SpaceTypeMetric({ label: 'Space Type' }));

        this.metrics = { 
            [Cost.name]: Cost, 
            [SpaceType.name]: SpaceType,
        };
    }

    createProject(data: IDemoProjectData) {
        return new DemoProject(data);
    }

    createProjectFromBlock(block: typeof Block) {
        const project = super.createProjectFromBlock(block) as DemoProject;
        project.setFunding('Capital General');

        return project;
    }

}


export default DemoAppStore;
