import { computed } from 'mobx';
import { ExtendedModel, model } from 'mobx-keystone';
import NumberFormat from 'react-number-format';

import { MetricPayload } from '../../../core/models/Metric';
import DashiSpaceTypeMetric from '../../../core/metrics/SpaceType';
import DemoProject from '../models/Project';
import { ProjectType } from '../../../core/models/Project';


@model('cub/SpaceTypeMetric')
class SpaceTypeMetric extends ExtendedModel(DashiSpaceTypeMetric, {}) {

    gsf(value: any, gsf: number) {
        // @ts-ignore
        return gsf === 0 ? 0 : Math.round(Object.values(value)[0] / (gsf / 100));
    }

    @computed
    get totalsByFundingType() {
        const totals: MetricPayload[] = [];

        this.cells.forEach(cell => {
            const i = cell.period - 1;
            const values = { ...cell.value };
            const project = cell.project as DemoProject;

            if (!totals[i]) {
                totals[i] = {};
            }

            if (!(project.funding in totals[i])) {
                totals[i][project.funding] = 0
            }

            Object.values(values).forEach(value => {
                totals[i][project.funding] += value;
            });
        });

        return totals;

    }

    renderSettings(project: DemoProject) {
        const { before, after } = project.metrics[this.name];

        let beforeVal:number = Object.values(before)[0] as number;
        let afterVal:number = Object.values(after)[0] as number;
        return (
            <div className="SpaceTypeMetricSettings ProjectSettings-section">
                <div className="ProjectSettings-entry">
                    <label htmlFor="ProjectGSF">Project Assignable/Gross</label>
                    <div className="labelled-input">
                        <NumberFormat
                            id="ProjectGSF"
                            thousandSeparator={true}
                            value={project.gsf}
                            onValueChange={e => project.setGsf(e.floatValue || 0)}
                        />
                        <label htmlFor="ProjectGSF">%</label>
                    </div>
                </div>

                {project.type !== ProjectType.New && (
                    <div className="ProjectSettings-entry">
                        <label htmlFor="">Program Use</label>
                        <div className="ProjectSettings-input">
                            <div className="ProjectSettings-cell">
                                <label htmlFor="ExistingUse">Existing Use Removed</label>
                                <select
                                    id="ExistingUse"
                                    value={Object.keys(before)[0]}
                                    onChange={e => project.setMetric(this.name, { after, before: { [e.target.value]: beforeVal }})}
                                >
                                    {this.options.map((option: string) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="ProjectSettings-cell">
                                <label htmlFor="ExistingASF">ASF</label>
                                <div className="labelled-input">
                                    <NumberFormat
                                        id="ExistingASF"
                                        thousandSeparator={true}
                                        value={beforeVal}
                                        onValueChange={e => project.setMetric(this.name, { after, before: { [Object.keys(before)[0]]: e.floatValue }})}
                                    />
                                    <label htmlFor="ExistingASF">SF/FT</label>
                                </div>
                            </div>

                            <div className="ProjectSettings-cell GSF">
                                <label htmlFor="">GSF</label>
                                <div className="cell-value">
                                    {this.gsf(before, project.gsf).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {project.type !== ProjectType.Demo && (
                    <div className="ProjectSettings-entry">
                        <label htmlFor="">Program Use</label>
                        <div className="ProjectSettings-input">
                            <div className="ProjectSettings-cell">
                                <label htmlFor="NewUse">New Use Introduced</label>
                                <select
                                    id="NewUse"
                                    value={Object.keys(after)[0]}
                                    onChange={e => project.setMetric(this.name, { before, after: { [e.target.value]: afterVal }})}
                                >
                                    {this.options.map((option: string) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="ProjectSettings-cell">
                                <label htmlFor="NewASF">
                                    {project.type === ProjectType.Reno ? <>&nbsp;</> : 'ASF' }
                                </label>
                                <div className="labelled-input">
                                    <NumberFormat
                                        id="NewASF"
                                        thousandSeparator={true}
                                        value={afterVal}
                                        onValueChange={e => project.setMetric(this.name, { before, after: { [Object.keys(after)[0]]: e.floatValue }})}
                                    />
                                    <label htmlFor="NewASF">SF/FT</label>
                                </div>
                            </div>

                            <div className="ProjectSettings-cell GSF">
                                <label htmlFor="">
                                    {project.type === ProjectType.Reno ? <>&nbsp;</> : 'GSF' }
                                </label>
                                <div className="cell-value">
                                    {this.gsf(after, project.gsf).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );

    }

}


export default SpaceTypeMetric;
