import React from 'react';
import { observer } from "mobx-react";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

import { useDashiStores } from '../../../core';
import GridIcon from '../icons/Grid';
import PlusIcon from '../icons/Plus';
import MinusIcon from '../icons/Minus';
import ExpandIcon from '../icons/Expand';

export default observer(function ToolBar({ zoomIn, zoomOut, menuButtons }) {
    const { ui } = useDashiStores().app;

    const togglePanels = () => {
        const bothOpen = !ui.timelinePanelIsOpen && !ui.chartsPanelIsOpen;
        ui.setChartsPanelOpen(bothOpen);
        ui.setTimelinePanelOpen(bothOpen);
    };

    return (
        <div className="ToolBar panel">
            <button onClick={zoomIn}><PlusIcon /></button>
            <button onClick={zoomOut}><MinusIcon /></button>
            <button onClick={togglePanels}><ExpandIcon /></button>
            {menuButtons.map((menuButton, i) => (
                <Menu
                    key={i}
                    direction={'right'}
                    arrow={true}
                    menuButton={<MenuButton><GridIcon /></MenuButton>}
                >
                    {menuButton.items.map(item => (
                        <MenuItem key={item.name} onClick={() => item.onClick()}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Menu>
            ))}
        </div>
    );
});
