import { action, observable, reaction } from 'mobx';

import { IDashiAppStore } from '../stores/AppStore';
import DashiCell, { IDashiCell } from '../models/Cell';


export interface IDashiCellStore {
    elements: IDashiCell[];
}

class DashiCellStore implements IDashiCellStore {

    @observable
    elements: IDashiCell[] = [];

    app: IDashiAppStore|undefined;

    setAppStore(app: IDashiAppStore) {
        this.app = app;

        reaction(
            () => `${Object.keys(app.metrics).length}${app.periods.length}${app.projectsKey}`,
            () => this.updateElements()
        );

        this.updateElements();
    }

    @action
    updateElements() {
        if (this.app !== undefined) {
            this.elements.splice(0, this.elements.length);

            this.elements = observable(
                this.app.projects.map(project => (
                    // @ts-ignore
                    this.app.periods.map(period => (
                        // @ts-ignore
                        Object.values(this.app.metrics).map(metric => (
                            new DashiCell(metric, project, period.i)
                        ))
                    )).reduce((a, b) => [ ...a, ...b], [])
                )).reduce((a, b) => [ ...a, ...b ], [])
            );
        }
    }

}


export default new DashiCellStore();
