import { useCallback, useEffect, useState } from 'react';
import { observer } from "mobx-react";
import Modal from '@sasaki-dev/react-modal';

import { useDashiStores } from '../..';
import TrashIcon from '../icons/Trash';


const formatDate = timestamp => {
    const date = new Date(timestamp);

    return `${date.toLocaleString('default', { day: '2-digit'})} ${date.toLocaleString('default', { month: 'short'})} ${date.getFullYear()}`;
};

function FileItem({ file, onDelete }) {
    return (
        <div className="FileItem">
            <div className="project-info">
                <h4>{file.scenarioName}</h4>
                <p>{file.description}</p>
            </div>

            <div className="project-details">
                <div>
                    <span>Date Created</span> {formatDate(file.created)}
                </div>

                {formatDate(file.created) !== formatDate(file.modified) && (
                    <div>
                        <span>Date Modified</span> {formatDate(file.modified)}
                    </div>
                )}

                <div>
                    <span>Created By</span> {file.createdBy}
                </div>
            </div>

            <div className="project-actions">
                <button onClick={onDelete}>
                    <TrashIcon />
                </button>
            </div>
        </div>
    );
}

export default observer(function FileModal() {
    const { app, persist } = useDashiStores();
    const [files, setFiles] = useState([]);
    const [current, setCurrent] = useState(null);
    const [selected, setSelected] = useState(null);
    const [refreshing, setRefreshing] = useState(true);

    useEffect(() =>  {
        if (app.ui.fileModalIsOpen) {
            setSelected(null);
            setCurrent(null);
            setRefreshing(true);

            persist.list().then((files = []) => {
                files.forEach(file => {
                    if (file.id === persist.id) {
                        setCurrent(file);
                    }
                });

                setFiles(files);
                setRefreshing(false);
            });
        }
    }, [app.ui.fileModalIsOpen, persist]);

    const onDelete = useCallback((file, e) => {
        e.stopPropagation();

        const _f = [ ...files ];
        _f.splice(files.indexOf(file), 1);
        setFiles(_f);

        persist.delete(file.id);
    }, [files, persist]);

    const load = useCallback(() => {
        persist.setId(selected);
        persist.load();
        app.ui.setFileModalOpen(false);
    }, [app.ui, persist, selected]);

    return (
        <Modal className={`FileModal ${app.ui.fileModalIsOpen ? 'active' : ''}`} onClose={() => app.ui.setFileModalOpen(false)}>
            <header>Manage &amp; Load a Scenario</header>

            <div className="content">
                {files.length > 0 ? (
                    <>
                        {current && (
                            <div className="current-file">
                                <h3>Current</h3>
                                <FileItem file={current} onDelete={e => { onDelete(current, e); setCurrent(null) }} />
                            </div>
                        )}

                        {!(current && files.length === 1) && <>
                            {current && (
                                <h3>Other Scenarios</h3>
                            )}

                            <ul>
                                {files.filter(file => file !== current).map((file, i) => (
                                    <li key={file.id} className={file.id === selected ? 'selected' : ''} onClick={() => setSelected(file.id)}>
                                        <FileItem file={file} onDelete={e => onDelete(file, e, i)} />
                                    </li>
                                ))}
                            </ul>
                        </>}
                    </>
                ) : (
                    refreshing ? (
                        <div className="status loading">
                            <span>Loading scenarios ...</span>
                        </div>
                    ) : (
                        <div className="status blank">
                            <span>No documents saved</span>
                        </div>
                    )
                )}

                <div className="modal-buttons">
                    <button className="create-fresh secondary" onClick={() => { persist.newDocument(); app.ui.setFileModalOpen(false)}}>
                        Create Fresh Scenario
                    </button>

                    <button className="secondary" onClick={() => app.ui.setFileModalOpen(false)}>
                        Cancel
                    </button>

                    <button className={`primary ${selected ? '' : 'disabled'}`} onClick={load}>
                        Load Scenario
                    </button>
                </div>
            </div>
        </Modal>
    );
});
