import {computed, observable} from 'mobx';
import {getRoot, Model, model, modelAction, prop} from 'mobx-keystone';

import {IDashiProject} from '../models/Project';
import {IDashiColorizerCanvas} from '../models/ColorizerCanvas';
import DashiColorizerState, {IDashiColorizerState} from '../models/Colorizer';


export interface IDashiColorizerStore {
    scale: number;
    height: number;
    width: number;
    imageIdx: number;
    canvases: IDashiColorizerCanvas[];
    projectsByCanvas: any;
    colorizers: Map<string, IDashiColorizerState>;
    colorizersByCanvas: IDashiColorizerStoreSet;
};

interface IDashiColorizerStoreSet {
    [key: string]: Map<string, IDashiColorizerState>;
}

@model('dashi/ColorizerStore')
class DashiColorizerStore extends Model({
    scale: prop<number>(0.8),
    height: prop<number>(800),
    width: prop<number>(600),
    canvases: prop<IDashiColorizerCanvas[]>(() => []),
}) implements IDashiColorizerStore {

    @computed
    get colorizers() {
        const states = new Map<string, IDashiColorizerState>();
        const {projects} = getRoot(this).persist;

        projects.filter((project: IDashiProject) => project.visible).forEach((project: IDashiProject) => {
            states.set(project.id, project.colorizerState);
        });

        return states;
    }

    @computed
    get colorizersByCanvas(): IDashiColorizerStoreSet {
        const states: IDashiColorizerStoreSet = {};
        const {projects} = getRoot(this).persist;//Note: this is the full project list (not filtered)

        //existing buildings are not listed in the projects data, but should show up on the canvas
        const allProjectIds: any = {};

        projects.forEach((project: IDashiProject) => {
            allProjectIds[project.id] = project;
        });

        this.canvases.forEach((canvas) => {
            states[canvas.title] = new Map<string, IDashiColorizerState>();
            canvas.assets.building.key.forEach((k) => {
                if (!allProjectIds[k]) {
                    states[canvas.title].set(k, new DashiColorizerState({}));
                }
            });
        });

        projects.filter((project: IDashiProject) => project.visible).forEach((project: IDashiProject) => {
            const canvasIds = this.canvasesByProject[project.id] || [];
            canvasIds.forEach(canvasId => {
                if (states[canvasId]) {
                    states[canvasId].set(project.id, project.colorizerState);
                }
            });
        });

        return states;
    }


    @computed
    get canvasesByProject() {
        const ans: { [key: string]: string[] } = {};
        Object.keys(this.projectsByCanvas).forEach((k) => {
            const arr = this.projectsByCanvas[k];
            arr.forEach(projId => {
                if (!ans[projId]) {
                    ans[projId] = [];
                }
                ans[projId].push(k);
            });
        });
        return ans;
    }

    @computed
    get projectsByCanvas() {
        return (
            this.canvases
                .map(canvas => ({
                    [canvas.title]: [...canvas.assets.landscape.key, ...canvas.assets.building.key]
                }))
                .reduce((a, b) => ({...a, ...b}), {})
        );
    }

    @modelAction
    setScale(scale: number) {
        this.scale = scale;
    }

    @modelAction
    setStageSize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    @modelAction
    addCanvas(canvas: IDashiColorizerCanvas) {
        this.canvases.push(canvas);
    }

    @observable
    imageIdx: number = 0;

    @modelAction
    setImageIdx(imageIdx: number) {
        this.imageIdx = imageIdx;
    }

    emitProjectClick(id: string) {
        const { persist } = getRoot(this);

        persist.projects.forEach((project: IDashiProject) => {
            if (project.id === id) {
                project.setSelected(!project.selected);
            }
            else {
                project.setSelected(false);
            }
        });
    }

}


export default DashiColorizerStore;
