import {observer} from "mobx-react";

import {useDashiStores} from '../..';
import {formatDataSet} from "./chartFunctions";
import AnnotatedLineChart from "./AnnotatedLineChart";
import ChartHeader from "./ChartHeader/ChartHeader";


export default observer(function ProjectTypeChart({style, title, width, height}) {
    const {app, config} = useDashiStores();
    const {ProjectTypeColors} = config;

    const data = {
        labels: app.periodLabels,
        datasets: [],
        axes: {x: {title: 'TIME'}, y: {title: 'ASF'}}
    };

    const periodData = app.metrics['SpaceType'].totalsByProjectType;

    if (periodData.length > 0) {
        Object.keys(periodData[0]).forEach((type, i) => {
            data.datasets.push(formatDataSet(ProjectTypeColors[type], type, periodData.map(types => types[type])));
        });
    }

    const totalASF = Object.values(app.currentPeriod.totals['SpaceType'] || {}).reduce((a, b) => a + b, 0);

    return (
        <div className="ProjectTypeChart" style={style}>
            <ChartHeader data={data} width={width} title={title}/>
            <AnnotatedLineChart
                width={width}
                height={height}
                data={data}
                scrubberTime={{
                    value: app.scrubber / config.periodScale,
                    info: totalASF.toLocaleString()
                }}
            />

        </div>
    );
});
