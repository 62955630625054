import App from './App';
import './index.scss';
import { bootstrap } from '../../core';
import firebaseConfig from './firebaseConfig';

import config from './config';
import AppStore from './stores/AppStore';
import UIStore from '../../core/stores/UIStore';
import PersistStore from './stores/PersistStore';
import ColorizerStore from './stores/ColorizerStore';

const ui = new UIStore({ selectedCharts: ['Use & ASF'] });
const app = new AppStore({ colorMode: 'use', ui });
const persist = new PersistStore({});
const colorizer = new ColorizerStore({});

bootstrap(firebaseConfig, config, { persist, app, colorizer });

export default App;
