/**
 * BitEncoder can be used to encode a number of values within the 24 bit RGB color space. Note that even for 32 bit PNGS, A (i.e. alpha) channel is avoided because round-tripping is not always reliable.
 * usage:
 * const bitEncoder = new BitEncoder();
 * bitEncoder.setRanges({id:15, lightness:8, shade:1});
 * bitEncoder.encode({id:66, lightness:225, shade:true}); --> 651651
 * bitEncoder.decode(651651); //---> e.g. {id:66, lightness:225, shade:true}
 *
 * As an alternative to specifying number of bits, arrays can be passed to setRanges as a way to encode enumerations. Bit lengths will be assigned to accommodate array length.
 * e.g. bitEncoder.setRanges({idx: 14, lightness: 8, type: ['A', 'B', 'C', 'D']});
 * bitEncoder.encode({idx: 1, lightness: 200, type: 'A'}); --> 7296
 * bitEncoder.decode(7296) --> {idx: 1, lightness: 200, type: 'A'}
 */
export default class BitEncoder {
    private ranges: any;
    private orderedKeys: any;

    constructor() {
        this.ranges = {};
        this.orderedKeys = [];
    }

    setRanges(ranges: any) {
        let sum = 0;
        const keys = Object.keys(ranges);
        this.orderedKeys = keys;
        this.orderedKeys.reverse();//when encoding/decoding, its easier to start on the right
        keys.forEach((k) => {
            const range = ranges[k];
            const bitCount = Array.isArray(range) ? Math.sqrt(range.length) : range;
            sum += bitCount;
        });
        if (sum > 24) throw new Error('invalid ranges -- only 24 bits available');
        this.ranges = ranges;
    }

    encode(obj: any) {
        let encoded = 0;
        let position = 0;
        this.orderedKeys.forEach((key: string, i: number) => {
            const range = this.ranges[key];
            const bitCount = Array.isArray(range) ? Math.sqrt(range.length) : range;
            const val = Array.isArray(range) ? range.indexOf(obj[key]) : obj[key];
            if (val < 0) {
                if (Array.isArray(range)) {
                    throw Error(`Cannot encode value for ${key}: ${obj[key]}. Not included in array.`);
                }
                throw Error(`Cannot encode value for ${key}: ${val}. It must be non-negative.`);
            }
            if (val >= Math.pow(2, bitCount)) {
                throw Error(`Cannot encode value for ${key}: ${val}. It must be smaller than ${Math.pow(2, bitCount)}.`);
            }
            const mult = Math.pow(2, position);
            // console.log(val, mult, val * mult);
            encoded += val * mult;//works with bools or ints
            position += bitCount;
        });
        return encoded;
    }

    decode(val: number) {
        let position = 0;
        const obj: any = {};
        let cVal = val;
        this.orderedKeys.forEach((key: string, i: number) => {
            const range = this.ranges[key];
            const bitCount = Array.isArray(range) ? Math.sqrt(range.length) : range;
            const prev = Math.pow(2, position);
            position += bitCount;
            const mult = Math.pow(2, position);
            const decoded = cVal % mult;
            // console.log(key, range, `${cVal} % ${mult} = ${decoded}`, prev, decoded / prev);
            const v = decoded / prev;
            obj[key] = Array.isArray(range) ? range[v] : v;
            cVal -= decoded;
        });
        //for sanity, return the keys with the original order
        const reverse = [...this.orderedKeys];
        reverse.reverse();
        const ans: any = {};

        reverse.forEach((key) => {
            ans[key] = obj[key];
        });
        return ans;
    }

    decodeRGB(r: number, g: number, b: number) {
        return this.decode((r << 16) + (g << 8) + b);
    }

    upperLimit(key:string) {
        const range = this.ranges[key];
        const bitCount = Array.isArray(range) ? Math.sqrt(range.length) : range;
        return Math.pow(2, bitCount);
    }
}

