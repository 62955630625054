import React from 'react';
import {observer} from "mobx-react";

const Legend = observer((props) => {
    const {items} = props;
    return <div className={"Legend"}>
        {items.map((item, i) => {
            return (<LegendItem key={i + '_' + item.label} color={item.backgroundColor} label={item.label}/>)
        })}
    </div>
});

const LegendItem = observer((props) => {
    const {color, label} = props;
    return (<div className={"LegendItem"}>
        <div style={{backgroundColor: color}} className={"colorDiv"}/>
        <div className={"label"}>{label}</div>
    </div>);

});


export default observer(function ChartsHeader({data, width, title}) {
        return (
            <div style={{width: width}} className={"ChartsHeader"}>
                <div className={"title"}>{title}</div>
                <div className={"legendMask"}>
                    <Legend items={data.datasets}/>
                </div>

            </div>
        );
    }
);
