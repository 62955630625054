// "E_L28",
//     "E_L33",
//     "E_L31",
//     "E_K21",
//     "E_H01",
//     "E_H04",
//     "E_H03",
//     "E_H35",
//     "530_BIOT",
//     "E_I08",
//     "E_H06",
//     "E_H05",
//     "E_I09E_I09",
//     "E_H07"

import {IDashiColorizerState} from "../../../models/Colorizer";

function hexToRgba(hex: string, a: number) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        a
    ] : [0, 0, 0, 0];
}

export const createColorStateImage = (keyIndex: string[], offscreen = true) => {
    const idxCount = keyIndex.length;
    let gradientCanvas: any;//TODO common interface for canvas?

    //NOTE 'offscreen' not currently working - check if REGL supports offscreen canvas
    // if (offscreen) {
    //     gradientCanvas = new OffscreenCanvas(idxCount, 1);
    // } else {
    //
    // }

    gradientCanvas = document.createElement('canvas');
    gradientCanvas.id = 'onscreen-debug-canvas';
    gradientCanvas.width = idxCount;
    gradientCanvas.height = 1;
    gradientCanvas.style = offscreen ? 'display:none' : 'zoom:10';
    // gradientCanvas.style = 'zoom:10; image-rendering: pixelated;';
    document.body.appendChild(gradientCanvas);

    let updateGradient;
    const setupGradient = () => {
        const ctx = gradientCanvas.getContext("2d");
        if (!ctx) return;
        const imgData = ctx.createImageData(idxCount, 1);
        // let cnt = 2;
        updateGradient = (colorizerStates: Map<string, IDashiColorizerState>) => {
            // console.log(phase);
            const values = [];
            for (let i = 0; i < keyIndex.length; i++) {
                const id = keyIndex[i];
                const arrIdx = i + 1;//HACK fix to work around shader issue
                let rgb = [0, 0, 0, 0];
                values[i] = null;
                if (colorizerStates && colorizerStates.get) {
                    const colorizerState = colorizerStates.get(id);
                    if (colorizerState) {
                        let colorHex = {color: colorizerState.color, alpha: 1};

                        if (colorHex) {
                            if (colorizerState.filteredOut) {
                                colorHex.color = '#aaaaaa';
                                colorHex.alpha = 0.1;//below 0.2 we just use plain alpha 0.1 = 50% etc
                            }
                            if (colorizerState.underway) {
                                colorHex.alpha = 0.25;
                            }
                            if (colorizerState.selected) {
                                colorHex.alpha = 0.8;
                            }
                            if (colorizerState.underway && colorizerState.selected) {
                                colorHex.alpha = 0.85;
                            }
                            values[i] = colorHex;
                            rgb = hexToRgba(colorHex.color, colorHex.alpha * 255);
                        }
                    }
                }

                const idx = arrIdx * 4;
                imgData.data[idx + 0] = rgb[0];
                imgData.data[idx + 1] = rgb[1];
                imgData.data[idx + 2] = rgb[2];
                imgData.data[idx + 3] = rgb[3];

            }
            ctx.putImageData(imgData, 0, 0);
            return {canvas: gradientCanvas, update: true, values};
        };
        return updateGradient(new Map<string, IDashiColorizerState>());
    };

    setupGradient();
    return {updateGradient};
};
