import { computed } from 'mobx';

import { IDashiProject } from './Project';
import { IDashiMetric, MetricBehavior, MetricPayload } from './Metric';


export interface IDashiCell {
    metric: IDashiMetric;
    payload: any;
    period: number;
    project: IDashiProject;
    value: MetricPayload;
};

class DashiCell implements IDashiCell {

    metric: IDashiMetric;
    project: IDashiProject;
    period: number;

    constructor(metric: IDashiMetric, project: IDashiProject, period: number) {
        this.metric = metric;
        this.project = project;
        this.period = period;
    }

    @computed
    get behavior() {
        if (this.project.periods.start <= this.period)  {
            if (this.project.periods.end >= this.period) {
                return MetricBehavior.During;
            }

            return MetricBehavior.After;
        }

        return MetricBehavior.Before;
    }

    @computed
    get payload(): any {
        return this.project.metrics[this.metric.name];
    }

    @computed
    get value() {
        return this.metric.getValue(
            this.behavior,
            this.payload,
            this.period - this.project.periods.start
        );
    }

}


export default DashiCell;
