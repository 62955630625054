import {observer} from "mobx-react";
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import {useDashiStores} from '../../index';
import TrendingUpIcon from '../icons/TrendingUp';
import FundingTypeChart from '../charts/FundingTypeChart';
import ProjectTypeDonut from '../charts/ProjectTypeDonut';
import SpaceTypeChart from '../charts/SpaceTypeChart';
import ProjectTypeChart from "../charts/ProjectTypeChart";
import CostsChart from "../charts/CostsChart";
import {fixedRectStyle} from "../../stores/LayoutStore";


export const ChartWrapper = observer(({slot, children}) => {
    return <div className="ChartWrapper" style={fixedRectStyle(slot)}>
        {children}
    </div>;
});

export default observer(function ChartsPanel() {
    const {ui, layout} = useDashiStores().app;
    const {chartsPanel} = layout;

    const SPACETYPECHART = 'Use & ASF';
    const PROJECTTYPECHART = 'Type & ASF';
    const COSTSCHART = 'Funding & Cost';
    const FUNDINGCHART = 'Funding & ASF';//this chart data is not ready (remove this from the chartsArr below to not render)
    const PROJECTTYPETIME = 'Type & Time';//this chart data is not ready (remove this from the chartsArr below to not render)

    const chartsArr = [
        {name: SPACETYPECHART},
        {name: PROJECTTYPECHART},
        {name: COSTSCHART},
        {name: FUNDINGCHART},
        {name: PROJECTTYPETIME}];

    const displayedCharts = ui.getDisplayedCharts(layout.chartSlotsCount);

    const isDisplayed = ({name}) => displayedCharts.indexOf(name) > -1;
    const isDisabled = ({name}) => {
        //all enabled if any slots open
        if (layout.chartSlotsCount > ui.selectedCharts.length) return false;
        //checkbox is disabled if all slots are taken and this is not one of them
        if (displayedCharts.indexOf(name) >= 0) return false;
        //we could potentially only disable UNCHECKED checkboxes so that deselection is always available...
        return true;
    }
    const isChecked = ({name}) => ui.selectedCharts.indexOf(name) > -1;

    const renderChart = (chart, slot) => {
        if (!chart) return null;
        const chartSize = {
            width: slot.size.width,
            height: slot.size.height-50,
        }
        if (chart.name === SPACETYPECHART) {
            return <SpaceTypeChart title={"Program Use over Time vs ASF"} width={chartSize.width}
                                   height={chartSize.height}/>;
        }
        if (chart.name === PROJECTTYPECHART) {
            return <ProjectTypeChart title={"Project Type over Time vs ASF"} width={chartSize.width}
                                     height={chartSize.height}/>;
        }
        if (chart.name === COSTSCHART) {
            return <CostsChart title={"Funding Type over time vs Esc. Total Cost"} width={chartSize.width}
                               height={chartSize.height}/>;
        }
        if (chart.name === FUNDINGCHART) {
            return <FundingTypeChart title={"Funding Type over Time vs ASF"} width={chartSize.width}
                                     height={chartSize.height}/>;
        }
        if (chart.name === PROJECTTYPETIME) {
            return <ProjectTypeDonut title={"Project Type at Time"} width={chartSize.width}
                                     height={chartSize.height}/>;
        }
    };

    const renderCharts = () => {
        const displayed = chartsArr.filter(isDisplayed);
        return layout.chartSlots.map((slot, i) => <ChartWrapper key={i} slot={slot}>
            {renderChart(displayed[i], slot)}
        </ChartWrapper>);
    };

    const renderChartFilter = () => {
        const displayed = chartsArr.filter(isDisplayed);

        //TODO use different behavior if only a single slot is available? This would be nicer as a radio-button in that case...
        if (layout.chartsPanel.size.width < 800) {
            return <Menu menuButton={<MenuButton>{displayed.map(d=>d.name).join('; ') || 'Select...'}</MenuButton>}>
                {chartsArr.map(chart => (
                    <MenuItem
                        key={chart.name}
                        type="checkbox"
                        disabled={layout.chartSlotsCount === 1 ? false : isDisabled(chart)}
                        checked={isChecked(chart)}
                        onClick={e =>  {
                            if (layout.chartSlotsCount === 1) {
                                chartsArr.forEach(_chart => ui.setChartSelection(_chart.name, _chart.name === chart.name))
                            }
                            else {
                                ui.setChartSelection(chart.name, e.checked);
                            }
                        }}
                    >
                        {chart.name}
                </MenuItem>
                ))}
            </Menu>
        }
        return chartsArr.map((chart, i) => {
            return <div key={chart.name} className={"indChartsLabel" + (isDisabled(chart) ? ' disabled' : '')}>
                <label>
                    <input
                        id={chart.name}
                        name={chart.name}
                        type="checkbox"
                        value={chart.name}
                        disabled={isDisabled(chart)}
                        checked={isChecked(chart)}
                        onChange={handleInputChange}/>
                    <div className={"chartTitle"}>
                        {chart.name}
                    </div>

                </label>
            </div>
        })
    };
    const handleInputChange = (event) => {
        const target = event.target;
        ui.setChartSelection(target.name, target.checked);
    }

    return (
        <div
            className={`ChartsPanel`}
            style={fixedRectStyle(chartsPanel)}
        >
            <div className={`panel cPanel ${!ui.chartsPanelIsOpen ? 'panel--closed' : ''}`}>
                <div className="panel__header">
                    <div className="panel-toggle">
                        <button onClick={() => ui.setChartsPanelOpen(!ui.chartsPanelIsOpen)}>
                            <TrendingUpIcon />
                        </button>

                        {ui.chartsPanelIsOpen && <h2>Analysis</h2>}
                    </div>

                    <div className={"chartFilter"}>
                        {ui.chartsPanelIsOpen && renderChartFilter()}
                    </div>
                </div>

                {ui.chartsPanelIsOpen && (
                    <div className="charts">
                        {renderCharts()}
                    </div>)
                }
            </div>
        </div>
    );
});
