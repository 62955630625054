import { useCallback, useEffect, useState } from 'react';
import { time } from 'react-timeline';
import { observer } from 'mobx-react';
import Modal from '@sasaki-dev/react-modal';
import NumberFormat from 'react-number-format';

import { useDashiStores } from '../..';
import TrashIcon from '../icons/Trash';
import { duration } from '../../util';
import { ProjectType } from '../../models/Project';


const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];


export default observer(function ProjectSettings() {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const { app, config, persist } = useDashiStores();
    const { selectedProject: project, ui } = app;

    useEffect(() => setDeleteConfirmation(false), [ui.projectSettingsIsOpen]);

    const cancel = useCallback(() => {
        if (project.snapshots.length > 0) {
            project.undo();
        }
        else { // fresh project
            persist.remove(project);
        }
        ui.setProjectSettingsOpen(false);
    }, [persist, project, ui]);

    if (!project) {
        return <></>;
    }

    const { design, start, end } = project.timestamps;

    return <>
        <div className={`ProjectSettings ${ui.projectSettingsIsOpen && project ? 'active' : ''}`}>
            <div className="ProjectSettings-gutter"  onClick={() => ui.setProjectSettingsOpen(false)} />

            <div className="ProjectSettings-window">
                <div className="ProjectSettings-header">
                    Project Data
                </div>

                {project && (
                    <div className="ProjectSettings-sections">
                        <div className="ProjectSettings-section">
                            <div className="ProjectSettings-entry">
                                <label htmlFor="ProjectName">Project Name</label>
                                <div className="ProjectSettings-input">
                                    <input id="ProjectName" type="text" value={project.name} onChange={e => project.setName(e.target.value)} />
                                </div>
                            </div>

                            <div className="ProjectSettings-entry">
                                <label htmlFor="Campus">Campus</label>
                                <select id="Campus" value={project.campus} onChange={e => project.setCampus(e.target.value)}>
                                    {Object.keys(config.CampusColors).map(campus => (
                                        <option value={campus}>{campus}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="ProjectSettings-section">
                            <div className="ProjectSettings-entry project-type-entry">
                                <label htmlFor="ProjectType">Project Type</label>
                                <div className="ProjectSettings-input">
                                    <select id="ProjectType" value={project.type} onChange={e => project.setType(e.target.value)}>
                                        {Object.values(ProjectType).filter(type => type !== ProjectType.Existing).map(type => (
                                            <option value={type}>{type}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="ProjectSettings-entry">
                                <label htmlFor="">Design Start</label>

                                <div className="ProjectSettings-input">
                                    <div className="ProjectSettings-entry-col">
                                        <div className="ProjectSettings-cell">
                                            <label htmlFor="">Month</label>
                                            <div className="cell-value">
                                                {months[Math.abs(Math.floor(project.timestamps.design / time.MONTH)) % 12]}
                                            </div>
                                        </div>

                                        <div className="ProjectSettings-cell">
                                            <label htmlFor="">Year</label>
                                            <div className="cell-value">
                                                {Math.floor(project.timestamps.design / time.YEAR) + config.startYear}
                                            </div>
                                        </div>
                                    </div>

                                    <label htmlFor="DesignDuration">Duration</label>
                                    <div className="labelled-input">
                                        <NumberFormat
                                            id="DesignDuration"
                                            allowNegative={false}
                                            value={duration(design, start)}
                                            onValueChange={value => project.setTimestamps({ design: project.timestamps.start - (((isNaN(value.floatValue) ? 0 : value.floatValue)) * time.MONTH) })}
                                        />
                                        <label htmlFor="DesignDuration">Months</label>
                                    </div>
                                </div>
                            </div>

                            <div className="ProjectSettings-entry">
                                <label htmlFor="">Construction Start</label>

                                <div className="ProjectSettings-input">
                                    <div className="ProjectSettings-entry-col">
                                        <div className="ProjectSettings-cell">
                                            <label htmlFor="StartMonth">Month</label>
                                            <select
                                                id="StartMonth"
                                                value={Math.floor(start/ time.MONTH) % 12}
                                                onChange={e => {
                                                    const yearStart = start - (start % time.YEAR);
                                                    const newStart = yearStart + (e.target.value * time.MONTH)
                                                    const delta = start - newStart;

                                                    project.setTimestamps({
                                                        design: design - delta,
                                                        start: newStart,
                                                        end: end - delta,
                                                    });
                                                }}
                                            >
                                                {months.map((month, i) => (
                                                    <option value={i}>{month}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="ProjectSettings-cell">
                                            <label htmlFor="StartYear">Year</label>
                                            <NumberFormat
                                                id="StartYear"
                                                allowNegative={false}
                                                value={config.startYear + Math.floor(start / time.YEAR)}
                                                onChange={e => {
                                                    const years = (e.target.value - config.startYear) * time.YEAR;
                                                    const months = start % time.YEAR;
                                                    const newStart = years + months;
                                                    const delta = start - newStart;

                                                    project.setTimestamps({
                                                        design: design - delta,
                                                        start: newStart,
                                                        end: end - delta,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <label htmlFor="ConstructionDuration">Duration</label>
                                    <div className="labelled-input">
                                        <NumberFormat
                                            id="ConstructionDuration"
                                            value={duration(start, end)}
                                            onValueChange={e => project.setTimestamps({ end: project.timestamps.start + (((isNaN(e.floatValue) ? 0 : e.floatValue)) * time.MONTH) })}
                                        />
                                        <label htmlFor="ConstructionDuration">Months</label>
                                    </div>
                                </div>
                            </div>

                            <div className="ProjectSettings-entry">
                                <label htmlFor="">Project End</label>

                                <div className="ProjectSettings-input">
                                    <div className="ProjectSettings-cell">
                                        <label htmlFor="">Month</label>
                                        <div className="cell-value">
                                            {months[Math.abs(Math.floor((project.timestamps.end - time.MONTH) / time.MONTH)) % 12]}
                                        </div>
                                    </div>

                                    <div className="ProjectSettings-cell">
                                        <label htmlFor="">Year</label>
                                        <div className="cell-value">
                                            {Math.floor((project.timestamps.end - time.MONTH) / time.YEAR) + config.startYear}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {Object.values(app.metrics).map(metric => metric.renderSettings(project))}
                    </div>
                )}

                <div className="ProjectSettings-footer">
                    <button className="delete-project" onClick={() => setDeleteConfirmation(true)}>
                        <TrashIcon />
                    </button>
                    <button className="secondary" onClick={cancel}>Cancel</button>
                    <button className="primary" onClick={() => ui.setProjectSettingsOpen(false)}>Done</button>
                </div>
            </div>
        </div>

        <Modal className={`delete-project-confirmation ${deleteConfirmation ? 'active' : ''}`} onClose={() => setDeleteConfirmation(false)}>
            <header>Delete Project</header>

            <div className="content">
                <p>Are you sure you want to delete {project.name}?</p>

                <div className="modal-buttons">
                    <button className="secondary" onClick={() => setDeleteConfirmation(false)}>Cancel</button>

                    <button
                        className="warn"
                        onClick={() => {
                            ui.setProjectSettingsOpen(false);
                            persist.remove(project);
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Modal>
    </>;
});
