import { Model, model, prop } from 'mobx-keystone';


export interface IDashiColorizerState {
    color: string;
    underway: boolean;
    filteredOut: boolean;
    selected: boolean;
};

@model('dashi/ColorizerState')
class DashiColorizerState extends Model({
    color: prop<string>('#ff00ff'),
    underway: prop<boolean>(false),
    filteredOut: prop<boolean>(false),
    selected: prop<boolean>(false),
}) implements IDashiColorizerState {}


export default DashiColorizerState;
