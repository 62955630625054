import { computed } from 'mobx';
import { getRoot, Model, model, prop } from 'mobx-keystone';

import cells from '../stores/CellStore';
import { IDashiCell } from './Cell';
import { MetricPayload } from './Metric';


export interface IDashiPeriod {
    i: number;
    label: string;
    cells: IDashiCell[];
    totals: { [key: string]: MetricPayload };
};

@model('dashi/Period')
class DashiPeriod extends Model({
    i: prop<number>(),
}) implements IDashiPeriod {

    @computed
    get label() {
        const { startYear } = getRoot(this).config;
        return `'${startYear - 2000 + Math.floor(this.i / 4)}`;
        // return this.i % 4 === 0 ?  : '';
    }

    @computed
    get cells() {
        return cells.elements.filter(cell => cell.period === this.i);
    }

    @computed
    get totals() {
        const totals: { [key: string]: MetricPayload } = {};

        this.cells.forEach(cell => {
            const values = { ...cell.value };//cell.value is wrapped as mobx proxy and is not iterable with Object.keys

            if (!(cell.metric.name in totals)) {
                totals[cell.metric.name] = {};
            }

            Object.keys(values).forEach(key => {
                if (!(key in totals[cell.metric.name])) {
                    totals[cell.metric.name][key] = 0;
                }

                totals[cell.metric.name][key] += values[key];
            });
        });

        return totals;
    }

    // TODO: Generalize these aggregators into a groupBy-like method
    @computed
    get totalsByProjectType() {
        const totals: { [key: string]: number } = {};

        this.cells.filter(cell => cell.metric.name === 'SpaceType').forEach(cell => {
            if (!(cell.project.type in totals)) {
                totals[cell.project.type] = 0
            }

            Object.values(cell.value).forEach(value => {
                totals[cell.project.type] += value;
            });
        });

        return totals;
    }

}


export default DashiPeriod;
