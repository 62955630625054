import { createContext, useContext } from 'react';
import { registerRootStore } from 'mobx-keystone';

import DefaultDashiConfig  from './config';
import FirebaseConfig from './types/FirebaseConfig';
import RootStore, {IRootStore, TopLevelStores} from './stores/RootStore';
import cells from './stores/CellStore';


let _context: any;
let root: IRootStore;

export function bootstrap(firebaseConfig: FirebaseConfig, config: any = {}, stores: TopLevelStores = {}) {
    root = new RootStore({ config: { ...firebaseConfig, ...DefaultDashiConfig, ...config }, ...stores  });
    registerRootStore(root);

    cells.setAppStore(root.app);
    _context = createContext(root);
}

export function getRootStore():IRootStore {
    return root;
}

export function useDashiStores():IRootStore {
    return useContext(_context);
}
