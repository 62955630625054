import { observer } from 'mobx-react';
import { useDashiStores } from '../../core';

import Login from './ui/Login';
import WelcomeModal from './ui/WelcomeModal';
import MenuBar from './../../core/ui/MenuBar';
import FileModal from './../../core/ui/FileModal';
import InfoModal from './../../core/ui/InfoModal';
import ChartsPanel from './../../core/ui/ChartsPanel';
import SaveAsModal from './../../core/ui/SaveAsModal';
import SelectionInfo from './../../core/ui/SelectionInfo';
import TimelinePanel from './../../core/ui/TimelinePanel';
import ProjectSettings from './../../core/ui/ProjectSettings';
import LockedChartPanel from './../../core/ui/LockedChartPanel';
import ColorizerStage from './../../core/ui/Colorizer/components/ColorizerStage';
import { ProjectType } from '../../core/models/Project';
import { duration } from '../../core/util';


export default observer(function DashiApp() {
    const { app, user } = useDashiStores();

    return (
        <div className="DashiApp">
            {!(user.isLoggedIn && user.isVerified) ? (
                <Login />
            ) : <>
                <WelcomeModal />
                <InfoModal />
                <SaveAsModal />
                <FileModal />

                <ColorizerStage />
                <ProjectSettings />

                {app.selectedProject && (
                    app.selectedProject.type === ProjectType.Existing ? (
                        <SelectionInfo
                            title="Building Overview"
                            behavior="Create New Project"
                            info={[
                                { name: 'Name', value: app.selectedProject.name },
                                { name: 'Use', value: app.selectedProject.use },
                                { name: 'ASF', value: app.selectedProject.asf.toLocaleString() },
                            ]}
                            onClick={() => {
                                app.selectedProject.setType(ProjectType.Reno);
                                app.ui.setProjectSettingsOpen(true);
                                app.triggerBlockSort();
                            }}
                        />
                    ) : (
                        <SelectionInfo
                            title="Project Overview"
                            behavior="Project Data"
                            info={[
                                { name: 'Name', value: app.selectedProject.name },
                                { name: 'Design', value: `${duration(app.selectedProject.timestamps.design, app.selectedProject.timestamps.start)} months`},
                                { name: 'Const', value: `${duration(app.selectedProject.timestamps.start, app.selectedProject.timestamps.end)} months`},
                            ]}
                            onClick={() => {
                                app.selectedProject.snapshot();
                                app.ui.setProjectSettingsOpen(true);
                            }}
                        />
                    )
                )}

                {app.ui.timelineIsLocked ? (
                    <LockedChartPanel />
                ) : (
                    <div className="panels">
                        <MenuBar />
                        <TimelinePanel />
                        <ChartsPanel />
                    </div>
                )}
            </>}
        </div>
    );
});
