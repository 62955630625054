import {getParent, Model, model, modelAction, prop} from 'mobx-keystone';
import {computed} from "mobx";
import {IDashiUIStore} from "./UIStore";
import {IDashiAppStore} from "./AppStore";

const MODES = {A: 'A', B: 'B'};

export interface ISize {
    width: number;
    height: number;
}

export interface IPosition {
    x: number;
    y: number;
}

export interface IRect {
    position: IPosition;
    size: ISize;
}

export const fixedRectStyle = (rect: IRect) => {
    return {
        top: rect.position.y,
        left: rect.position.x,
        width: rect.size.width,
        height: rect.size.height,
    }
}

export interface IDashiLayoutStore {
    setSize(width: number, height: number): void,

    chartSlotsCount: number,
    chartsPanel: IRect,
    chartSlots: IRect[],
    timelinePanel: IRect
    selectionInfoRect: IRect;
}

@model('dashi/DashiLayoutStore')
class DashiLayoutStore extends Model({
    width: prop<number>(window.innerWidth),
    height: prop<number>(window.innerHeight),
    timelinePanelMinWidth: prop<number>(850),
    timelinePanelCollapsedHeight: prop<number>(60),
    chartPanelMinHeight: prop<number>(425),
    chartMinWidth: prop<number>(525),
}) implements IDashiLayoutStore {

    constructor(snapshot: any) {
        super(snapshot);
        window.addEventListener('resize', () => {
            this.setSize(window.innerWidth, window.innerHeight);
        })
        this.setSize(window.innerWidth, window.innerHeight);
    }

    @computed
    get uiStore(): IDashiUIStore | undefined {
        return this.appStore?.ui;
    }

    @computed
    get appStore(): IDashiAppStore | undefined {
        return getParent(this);
    }

    @computed
    get chartPanelHeight(): number {
        return Math.max(0.33 * this.height, this.chartPanelMinHeight);
    }

    @computed
    get timelinePanelWidth(): number {
        return Math.max(0.33 * this.width, this.timelinePanelMinWidth);
    }

    @computed
    get chartsPanel(): IRect {

        const chPanelY = this.height - this.chartPanelHeight;
        const fullChartRect = {
            position: {x: 0, y: chPanelY},
            size: {width: this.width, height: this.chartPanelHeight}
        };

        if (!this.uiStore?.timelinePanelIsOpen) {
            return fullChartRect;
        }

        if (!this.uiStore?.chartsPanelIsOpen) {
            fullChartRect.size.width = this.width - this.timelinePanelWidth;
            return fullChartRect;
        }

        //cases here
        if (this.layoutMode === MODES.B) {
            fullChartRect.size.width = this.width - this.timelinePanelWidth;
        }
        return fullChartRect;
    }

    @computed
    get colorizerWindow(): IRect {
        return {
            position: {
                x: 0,
                y: 0,
            },
            size: {
                width: this.width - (this.uiStore?.timelinePanelIsOpen ? this.timelinePanelWidth : 0),
                height: this.height - (this.uiStore?.chartsPanelIsOpen ? this.chartPanelHeight : 0),
            }
        };
    }

    @computed
    get selectionInfoRect(): IRect {
        const width = Math.max(216, Math.min(350, this.colorizerWindow.size.width * 0.25));
        const height = 500;
        return {
            position: {
                x: this.colorizerWindow.position.x + this.colorizerWindow.size.width - width,
                y: this.uiStore?.timelinePanelIsOpen ? 0 : this.timelinePanelCollapsedHeight,
            },
            size: {
                width: width,
                height: height,
            }
        };
    }

    @computed
    get timelinePanel(): IRect {
        const tPanelX = this.width - this.timelinePanelWidth;
        const fullTimelineRect = {
            position: {x: tPanelX, y: 0},
            size: {width: this.timelinePanelWidth, height: this.height}
        };
        if (!this.uiStore?.timelinePanelIsOpen) {
            fullTimelineRect.size.height = this.timelinePanelCollapsedHeight;
            return fullTimelineRect;
        }

        if (!this.uiStore?.chartsPanelIsOpen) {
            return fullTimelineRect;
        }
        //cases here
        if (this.layoutMode === MODES.A) {
            fullTimelineRect.size.height = this.height - this.chartPanelHeight;
        }
        return fullTimelineRect;
    }


    @computed
    get chartSlotsCount(): number {
        return Math.round(this.chartsPanel.size.width / this.chartMinWidth);
    }

    @computed
    get innerChartArea(): IRect {
        return {
            position: {
                x: 0,
                y: 0,
            },
            size: {
                width: this.chartsPanel.size.width - 40,//TODO can this be derived from DOM state?
                height: this.chartsPanel.size.height - 70,
            }
        }
    }

    @computed
    get chartSlots(): IRect[] {
        const slots = [];
        const effectiveSlots = Math.min(this.chartSlotsCount, this.uiStore?.currentCheckedCount || 0);
        const eachWidth = this.innerChartArea.size.width / effectiveSlots;
        for (let i = 0; i < effectiveSlots; i++) {
            slots.push({
                position: {
                    x: this.innerChartArea.position.x + i * eachWidth,
                    y: 0,
                },
                size: {
                    width: eachWidth,
                    height: this.innerChartArea.size.height,
                }
            });
        }
        return slots;
    }

    @computed
    get aspectRatio(): number {
        return this.width / this.height;
    }

    @computed
    get layoutMode(): string {
        if (this.aspectRatio < 1.7) {//4:3
            return MODES.A;
        } else {
            return MODES.B;
        }
    }

    @modelAction
    setSize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

}


export default DashiLayoutStore;
