import { computed, observable } from 'mobx';
import { getRoot, Model, model, modelAction, prop } from 'mobx-keystone';
import firebase from 'firebase/app';
import 'firebase/auth';


export interface IDashiUserStore {
    user: any;
    displayName: string;
    isLoggedIn: boolean;
    isVerified: boolean;
    logout(): void;
    whitelist: string[];
    setUser(user: any): void;
};

@model('dashi/UserStore')
class DashiUserStore extends Model({
    whitelist: prop<string[]>(() => []),
}) implements IDashiUserStore {

    @observable
    user: any = null;

    @computed
    get displayName() {
        return this.user ? this.user.displayName : '';
    }

    onAttachedToRootStore() {
        if (firebase.apps.length === 0) {
            firebase.initializeApp(getRoot(this).config);
        }

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setUser(user);
            }
            else {
                this.setUser(null);
            }
        });
    }


    logout() {
        firebase.auth().signOut();
        window.location.reload();
    }


    @computed
    get isLoggedIn() {
        return this.user !== null;
    }

    @computed
    get isVerified() {
        return this.user && this.user.emailVerified;
    }

    @modelAction
    setUser(user: any) {
        this.user = user !== null ? user : null;
    }

}


export default DashiUserStore;
