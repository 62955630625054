import React from 'react';
import { observer } from "mobx-react";

import { useDashiStores } from '../..';
import { formatDataSet } from "./chartFunctions";
import AnnotatedLineChart from "./AnnotatedLineChart";
import ChartHeader from "./ChartHeader/ChartHeader";


export default observer(function SpaceTypeChart({ style, title, width, height }) {
    const { config, app } = useDashiStores();
    const { SpaceTypeColors } = config;

    const data = {
        labels: app.periodLabels,
        datasets: [],
        axes: {x: {title: 'TIME'}, y: {title: 'ASF'}}
    };

    const periodData = app.metrics['SpaceType'].totals;

    Object.keys(periodData[0] || {}).forEach((key, i) => {
        data.datasets.push(formatDataSet(SpaceTypeColors[key], key, periodData.map(p => p[key])));
    });

    const totalASF = Object.values(app.currentPeriod.totals['SpaceType'] || {}).reduce((a, b) => a + b, 0);

    return (
        <div className="SpaceTypeChart" style={style}>
            <ChartHeader data={data} width={width} title = {title}/>
            <AnnotatedLineChart
                width={width}
                height={height}
                data={data}
                scrubberTime={{
                    value: app.scrubber / config.periodScale,
                    info: totalASF.toLocaleString()
                }}
            />
        </div>
    );
});
