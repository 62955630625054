import {Model, model, modelAction, prop} from 'mobx-keystone';
import {computed} from "mobx";


export interface IDashiUIStore {
    chartsPanelIsOpen: boolean;
    fileModalIsOpen: boolean;
    infoModalIsOpen: boolean;
    projectSettingsIsOpen: boolean;
    saveAsModalIsOpen: boolean;
    settingsDialogIsOpen: boolean;
    timelinePanelIsOpen: boolean;
    timelineIsLocked: boolean;
    welcomeModalIsOpen: boolean;
    closeWelcomeModal(): void;
    currentCheckedCount: number;
    selectedCharts: string[];
    setChartSelection(name: string, val: boolean): void;
    setChartsPanelOpen(isOpen: boolean): void;
    setFileModalOpen(isOpen: boolean): void;
    setInfoModalOpen(isOpen: boolean): void;
    setProjectSettingsOpen(isOpen: boolean): void;
    setSaveAsModalOpen(isOpen: boolean): void;
    setSettingsDialogOpen(isOpen: boolean): void;
    setTimelineLocked(locked: boolean): void;
    setTimelinePanelOpen(isOpen: boolean): void;
    getDisplayedCharts(numSlots:number): string[];
}

@model('dashi/UIStore')
class DashiUIStore extends Model({
    chartsPanelIsOpen: prop(true),
    fileModalIsOpen: prop(false),
    infoModalIsOpen: prop(false),
    projectSettingsIsOpen: prop(false),
    saveAsModalIsOpen: prop(false),
    settingsDialogIsOpen: prop(false),
    timelinePanelIsOpen: prop(true),
    timelineIsLocked: prop(false),
    welcomeModalIsOpen: prop<boolean>(() => !window.localStorage.getItem('welcomeModalClosed')),
    selectedCharts: prop<string[]>(() => ['Use & ASF', 'Type & Time']),
}) implements IDashiUIStore {

    @modelAction
    closeWelcomeModal() {
        this.welcomeModalIsOpen = false;
        window.localStorage.setItem('welcomeModalClosed', 'true');
    }

    @modelAction
    setChartsPanelOpen(isOpen: boolean = true) {
        this.chartsPanelIsOpen = isOpen;
    }

    @modelAction
    setChartSelection(name: string, val: boolean) {
        if (val && this.selectedCharts.indexOf(name) === -1) {
            this.selectedCharts.push(name);
        }
        if (!val) {
            const index = this.selectedCharts.indexOf(name);
            if (index > -1) {
                this.selectedCharts.splice(index, 1);
            }
        }
    }

    @modelAction
    setFileModalOpen(isOpen: boolean = true) {
        this.fileModalIsOpen = isOpen;
    }

    @modelAction
    setInfoModalOpen(isOpen: boolean = true) {
        this.infoModalIsOpen = isOpen;
    }

    @modelAction
    setProjectSettingsOpen(isOpen: boolean = true) {
        this.projectSettingsIsOpen = isOpen;
    }

    @modelAction
    setSaveAsModalOpen(isOpen: boolean = true) {
        this.saveAsModalIsOpen = isOpen;
    }

    @modelAction
    setSettingsDialogOpen(isOpen: boolean = true) {
        this.settingsDialogIsOpen = isOpen;
    }

    @modelAction
    setTimelineLocked(locked: boolean = true) {
        this.timelineIsLocked = locked;
    }

    @modelAction
    setTimelinePanelOpen(isOpen: boolean = true) {
        this.timelinePanelIsOpen = isOpen;
    }

    getChartCheckBoxClickState(name: string): boolean {
        return this.selectedCharts.indexOf(name) > -1;
    }

    @computed
    get currentCheckedCount() {
        return this.selectedCharts.length;
    }

    getDisplayedCharts(numSlots:number) {
        return this.selectedCharts.slice(0, numSlots);
    }
}


export default DashiUIStore;
