import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';

import { IDashiColorizerAssets } from './ColorizerAssets';


export interface IDashiColorizerCanvas {
    imageHeight: number;
    imageWidth: number;
    scale: number;
    title: string;
    assets: IDashiColorizerAssets;
};


@model('dashi/ColorizerCanvas')
class DashiColorizerCanvas extends Model({
    assets: prop<IDashiColorizerAssets>(),
    imageHeight: prop<number>(0),
    imageWidth: prop<number>(0),
    scale: prop<number>(1),
    title: prop<string>(''),
}) implements IDashiColorizerCanvas {

    @computed
    get height() {
        return this.scale * this.imageHeight;
    }

    @computed
    get width() {
        return this.scale * this.imageWidth;
    }

}


export default DashiColorizerCanvas;
