import { Block } from 'react-timeline';
import { autorun, computed, observable } from 'mobx';
import { getSnapshot, Model, model, modelAction, prop } from 'mobx-keystone';

import { getRootStore } from '..';
import { IDashiMetric } from './Metric';
import { IDashiColorizerState } from './Colorizer';


export enum ProjectType {
    New = "New Construction",
    Reno = "Renovation",
    Demo = "Demolition",
    Existing = 'Existing',
}

export enum ProjectVisibility {
    Hidden = "Hidden",
    Visible = "Visible",
    NotStarted = "NotStarted",
    Underway = "Underway",
}

export interface IProjectData {
    id: string;
    mapped?: boolean;
    metrics: {[key:string]: IDashiMetric};
    name: string;
    type: ProjectType,
    timestamps: ProjectTimeline;
    timelineY?: number;
}

export type ProjectTimeline = {
    start: number;
    end: number;
    design: number;
}

type TimelineParameters = {
    start?: number;
    end?: number;
    design?: number;
};

type BlockEvent = {
    start: number;
    end: number;
    removed: boolean;
    selected: boolean;
    blockRight?: typeof Block;
    blockLeft?: typeof Block;
};

export interface IDashiProject {
    $modelId: string;
    id: string;
    name: string;
    block: typeof Block;
    blocks: (typeof Block)[];
    campus: string;
    color: string;
    colorizerState: IDashiColorizerState;
    mapped: boolean;
    metrics: any;
    periods: ProjectTimeline;
    phase?: string;
    visibility: ProjectVisibility;
    selected: boolean;
    snapshots: any[];
    timestamps: ProjectTimeline;
    type: ProjectType;
    underway: boolean;
    visible: boolean;
    setCampus(campus: string): void;
    setMetric(name: string, payload: any): void;
    setName(name: string): void;
    setSelected(selected: boolean): void;
    setType(type: ProjectType): void;
    setTimelineY(y: number): void;
    setTimestamps(timeline: TimelineParameters): void;
    snapshot(): any;
    undo(): void;
};

@model('dashi/Project')
class DashiProject extends Model({
    id: prop<string>(),
    name: prop<string>(''),
    timestamps: prop<ProjectTimeline>(),
    timelineY: prop<number>(0),
    type: prop<ProjectType>(ProjectType.New),
    metrics: prop<any>(() => {}),
    mapped: prop<boolean>(true),
    _campus: prop<string>(''),
}) implements IDashiProject {

    onInit() {
        this.constructBlocks();

        // Keep Alive
        autorun(() => this.color);
    }

    @modelAction
    constructBlocks() {
        const { config, persist } = getRootStore();

        const block = this.block ? this.block : new Block(this.$modelId, this.timestamps.start, this.timestamps.end, this.timelineY);
        const designBlock = new Block(this.$modelId, this.timestamps.design, this.timestamps.start, this.timelineY);

        if (!this.mapped) {
            block.setClassName("unmapped");
            designBlock.setClassName("unmapped");
        }

        block.onChange = ({ selected, start, end, blockLeft, removed }: BlockEvent) => {
            if (removed) {
                return persist.remove(this);
            }

            this.setTimestamps({ start, end, design: blockLeft.start });

            if (!designBlock.selected && this.selected !== selected) {
                this.setSelected(selected, false);
            }
        }

        designBlock.onChange = ({ end, selected, start, blockRight, removed }: BlockEvent) => {
            if (removed) {
                return persist.remove(this);
            }

            this.setTimestamps({ design: start, start: end, end: blockRight.end });

            if (!block.selected && this.selected !== selected) {
                this.setSelected(selected, false);
            }
        };

        block.setName(this.name);
        block.setColor(this.color);
        designBlock.setColor(config.designPhaseColor);

        block.setBlockLeft(designBlock);
        designBlock.setBlockRight(block);

        this.blocks = [block, designBlock];
    }

    @modelAction
    setMetric(name: string, payload: any) {
        this.metrics[name] = payload;
    }

    @observable
    blocks: (typeof Block)[] = [];

    @computed
    get block() {
        return this.blocks[0];
    }

    @observable
    snapshots: any[] = [];

    @computed
    get periods() {
        const { periodScale } = getRootStore().config;
        const { design, start, end } = this.timestamps;

        const periods = {
            design: Math.floor(design / periodScale),
            start: Math.floor(start / periodScale),
            end: Math.floor(end / periodScale),
        };

        return periods;
    }

    @computed
    get phase() {
        const { phases } = getRootStore().config;

        for (let phase in phases) {
            if (this.timestamps.start >= phases[phase][0] && this.timestamps.start < phases[phase][1]) {
                return phase;
            }
        }

        return undefined;
    }

    @modelAction
    snapshot() {
        this.snapshots.push(getSnapshot(this));
    }

    @computed
    get campus() {
        if (this._campus !== '') {
            return this._campus;
        }
        else {
            const { projectsByCanvas } = getRootStore().colorizer;

            let campus = '';
            Object.entries(projectsByCanvas).some(([canvas, projects]) => {
                if ((projects as string[]).indexOf(this.id) !== -1) {
                    campus = canvas;
                    return true;
                }
                return false;
            });

            return campus;
        }
    }

    @computed
    get visibility() {
        const { scrubber } = getRootStore().app;
        const { start, end } = this.timestamps;

        if (this.type === ProjectType.New) {
            if (scrubber >= start) {
                return scrubber <= end ? ProjectVisibility.Underway : ProjectVisibility.Visible;
            }

            return ProjectVisibility.Hidden;
        }

        if (this.type === ProjectType.Reno) {
            if (scrubber >= start) {
                return scrubber <= end ? ProjectVisibility.Underway : ProjectVisibility.Visible;
            }

            return ProjectVisibility.NotStarted;
        }

        if (this.type === ProjectType.Demo) {
            if (scrubber >= start) {
                return scrubber <= end ? ProjectVisibility.Underway : ProjectVisibility.Hidden;
            }

            return ProjectVisibility.Visible;
        }

        return ProjectVisibility.Hidden;
    }

    @computed
    get color() {
        if (this.type === ProjectType.Existing) {
            return '#dfdfdf';
        }
        const { config, app } = getRootStore();
        // @ts-ignore
        const color = config[config.colorBy[app.colorMode]][this[app.colorMode]] || '#ff00ff';//highlight in magenta (this color should never show)

        if (this.block) {
            this.block.setColor(color);
        }

        return color;
    }

    @computed
    get colorizerState() {
        const { color, underway, selected, filteredOut } = this;
        return { color, underway, selected, filteredOut };
    }

    @observable
    selected: boolean = false;

    @computed
    get underway() {
        return this.visibility === ProjectVisibility.Underway;
    }

    @computed
    get filteredOut():boolean {
        const { projects } = getRootStore().app;
        return this.type !== ProjectType.Existing && projects.indexOf(this) < 0;
    }

    @computed
    get visible() {
        if (this.type !== ProjectType.Existing) {
            if (this.visibility === ProjectVisibility.Hidden) {
                return false;
            }

            if (this.type === ProjectType.Demo) {
                return this.visibility === ProjectVisibility.Underway || this.visibility === ProjectVisibility.NotStarted || this.visibility === ProjectVisibility.Visible
            }
            else if (this.type === ProjectType.New) {
                return this.visibility !== ProjectVisibility.NotStarted
            }
        }

        return true;
    }

    @modelAction
    setCampus(campus: string) {
        this._campus = campus;
    }

    @modelAction
    setName(name: string) {
        this.name = name;
        this.block.setName(name);
    }

    @modelAction
    setTimestamps(timestamps: TimelineParameters) {
        this.timestamps = { ...this.timestamps, ...timestamps };

        this.blocks[1].setStart(this.timestamps.design);
        this.blocks[0].setStart(this.timestamps.start);
        this.blocks[0].setEnd(this.timestamps.end);
    }

    @modelAction
    setSelected(selected: boolean = true, updateBlock: boolean = true) {
        this.selected = selected;

        if (updateBlock) {
            this.block.setSelected(selected);
        }
    }

    @modelAction
    setTimelineY(y: number) {
        this.timelineY = y;
        this.blocks.forEach((block: typeof Block) => block.setY(y));
    }

    @modelAction
    setType(type: ProjectType) {
        this.type = type;
    }

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setCampus(snapshot.campus);
            this.setType(snapshot.type);
            this.setTimestamps(snapshot.timestamps);

            Object.keys(this.metrics).forEach((metric: string) => {
                this.setMetric(metric, snapshot.metrics[metric]);
            });
        }
    }

}


export default DashiProject;
