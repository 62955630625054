import { observer } from "mobx-react";
import { Doughnut } from "react-chartjs-2";

import { useDashiStores } from '../..';
import ChartHeader from './ChartHeader/ChartHeader';


export default observer(function ProjectTypeDonut({ width, height, title }) {
    const { app, config } = useDashiStores();
    const { ProjectTypeColors } = config;

    const size = Math.min(width, height);
    const colors = Object.values(ProjectTypeColors);

    const data = app.currentPeriod.totalsByProjectType;
    const values = Object.keys(ProjectTypeColors).map(type => data[type] || 0);

    const currentTypes = Object.entries(data).reduce((types, [type, value]) => value > 0 ? [ ...types, type ] : types, []);

    const headerData = {
        datasets: currentTypes.map(label => ({ label, backgroundColor: ProjectTypeColors[label] })),
    };

    const chartData = {
        datasets: [{
            data: values,
            backgroundColor: colors,
        }],

        labels: Object.keys(ProjectTypeColors),
    };

    const options = {
        responsive: false,
        plugins: {
            legend: {
                display: false,
            },
        }
    };

    return (
        <div className="BedMixChart" >
            <ChartHeader data={headerData} width={size} title={title} />
            <Doughnut width={size} height={size} data={chartData} options={options} />
        </div>
    );
});

