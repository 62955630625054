import { Model, model, prop } from 'mobx-keystone';


export type DashiColorizerData = {
    id: string;
    graphic: string;
    key: string[];
};

export interface IDashiColorizerAssets {
    base: string;
    landscape: DashiColorizerData;
    building: DashiColorizerData;
};

@model('dashi/ColorizerAssets')
class DashiColorizerAssets extends Model({
    base: prop<string>(),
    landscape: prop<DashiColorizerData>(),
    building: prop<DashiColorizerData>(),
}) implements IDashiColorizerAssets {}


export default DashiColorizerAssets;
