import { observer } from 'mobx-react';
import Modal from '@sasaki-dev/react-modal';

import { useDashiStores } from '../..';


export default observer(function InfoModal() {
    const { app, config, user } = useDashiStores();

    return (
        <Modal className={`InfoModal ${app.ui.infoModalIsOpen ? 'active' : ''}`} onClose={() => app.ui.setInfoModalOpen(false)}>
            <header>Dashboard Information</header>

            <div className="content">
                <ul>
                    <li>
                        <label>Product</label>
                        <span>{config.product}</span>
                    </li>

                    <li>
                        <label>Project</label>
                        <span>{config.project}</span>
                    </li>

                    {config.source && (
                        <li>
                            <label>Data Source</label>
                            <span>{config.source}</span>
                        </li>
                    )}

                    {config.contact && (
                        <li>
                            <label>Contact</label>
                            <span>{config.contact}</span>
                        </li>

                    )}

                    {config.userGuide && (
                        <li>
                            <label>User Guide</label>
                            <span><a rel="noopener noreferrer" target="_blank" href={config.userGuide}>{config.userGuide}</a></span>
                        </li>
                    )}
                </ul>

                <div className="modal-buttons">
                    <button className="text" onClick={() => user.logout()}>
                        Logout {user.displayName}
                    </button>
                    <button className="primary" onClick={() => app.ui.setInfoModalOpen(false)}>
                        Done
                    </button>
                </div>
            </div>
        </Modal>
    );
});
