import React from 'react';
import { observer } from "mobx-react";

import { useDashiStores } from '../../..';
import ColorizerCanvas from "./ColorizerCanvas";
import { LayerIdCanvas } from "./LayerIdCanvas";

const zoomStyle = (imageWidth, imageHeight, zoom) => {
    const shift = {
        x: (imageWidth - (zoom * imageWidth))/2,
        y: (imageHeight - (zoom * imageHeight))/2,
    }
    shift.x /= zoom;
    shift.y /= zoom;
    return {width: imageWidth, height: imageHeight, transform: `scale(${zoom}) translate(${-shift.x}px,${-shift.y}px )`};//
};

export default observer(function ColorizerStack({ id, canvas, position, onButtonClick }) {
    const { colorizer, app } = useDashiStores();
    const { layout } = app;
    const { width, height, imageWidth, imageHeight } = canvas;

    const extraWidth = width * layout.width / layout.colorizerWindow.size.width;
    const extraHeight = height * layout.height / layout.colorizerWindow.size.height;

    return (
        <div className={'ColorizerStack'} style={{ width, height, left: position[0], top: position[1] }}>
            <div id={id} className={'zoom-to-extra'} style={{ width:extraWidth, height:extraHeight }} />
            <div className={'title'} onClick={onButtonClick}>
                {canvas.title}
            </div>

            <div className={'scaler'} style={zoomStyle(imageWidth, imageHeight, width / imageWidth)}>
                <img alt="" src={canvas.assets.base} width={imageWidth} height={imageHeight} />

                <LayerIdCanvas store={colorizer} phase={colorizer.imageIdx} canvas={canvas} width={imageWidth} height={imageHeight} />
                <ColorizerCanvas canvasId={id} store={colorizer} phase={colorizer.imageIdx} canvas={canvas} width={imageWidth} height={imageHeight} />
            </div>
        </div>
    );
});
