import React from 'react';
import {observer} from "mobx-react";
import {Line} from "react-chartjs-2";
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import {getAnnotations} from "./chartFunctions";
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
Chart.register(annotationPlugin);

export default observer(function AnnotatedLineChart({scrubberTime, data, referenceLine, width, height}) {
    const options = {
        plugins: {
            legend: {
                display: false,
            },
            annotation: {
                annotations: getAnnotations(scrubberTime, referenceLine)
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    text: (data.axes && data.axes.x) ? data.axes.x.title : '',
                    display: false,
                }
            },
            y: {
                stacked: true,
                title: {
                    text: (data.axes && data.axes.y) ? data.axes.y.title : '',
                    display: true,
                }
            },
        },
    };
    const style = {width, height};
// console.log('AnnotatedLineChart render called: ', width, height, data);
    return (
        <div className={'chartJsResponsiveParent'} style={style}>
            <Line
                options={options}
                data={data}
                plugins={[ChartAnnotation]}
            />

        </div>

    );
});
