import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';

import cells from '../stores/CellStore';
import { IDashiCell } from './Cell';
import { IDashiProject } from './Project';


export type MetricPayload = {
    [key: string]: number;
};

export enum MetricBehavior {
    Before,
    During,
    After,
};

export interface IDashiMetric {
    displayUnits(value: number): string;
    label: string;
    name: string;
    generatePayload(...args: any[]): object;
    getValue(behavior: MetricBehavior, payload: MetricPayload, index: number): MetricPayload;
    cells: IDashiCell[];
    renderSettings(project: IDashiProject): any;
};

@model('dashi/Metric')
class DashiMetric extends Model({
    label: prop<string>(),
}) implements IDashiMetric {

    name = 'Base Metric';

    generatePayload(..._: any[]): object {
        throw new Error('`generatePayload` not implemented');
    }

    getValue(_: MetricBehavior, __: any, ___: number): MetricPayload {
        throw new Error('`getValue` not implemented');
    }

    renderSettings(_: IDashiProject) {
        throw new Error('`renderSettings` not implemented');
    }

    displayUnits(value: number) {
        return `${value} units`;
    }

    @computed
    get cells(): IDashiCell[] {
        return cells.elements.filter(cell => cell.metric === this);
    }

    @computed
    get totals() {
        const totals: { [key: string]: number }[] = [];

        this.cells.forEach(cell => {
            const i = cell.period - 1;
            const values = { ...cell.value };

            if (!totals[i]) {
                totals[i] = {};
            }

            Object.keys(values).forEach(key => {
                if (!(key in totals[i])) {
                    totals[i][key] = 0;
                }

                totals[i][key] += values[key];
            });
        });

        return totals;
    }

    @computed
    get totalsByProjectType() {
        const totals: MetricPayload[] = [];

        this.cells.forEach(cell => {
            const i = cell.period - 1;
            const values = { ...cell.value };

            if (!totals[i]) {
                totals[i] = {};
            }

            if (!(cell.project.type in totals[i])) {
                totals[i][cell.project.type] = 0
            }

            Object.values(values).forEach(value => {
                totals[i][cell.project.type] += value;
            });


        });

        return totals;
    }

}


export default DashiMetric;
