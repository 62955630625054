import firebase from 'firebase/app';
import 'firebase/firestore';
import { computed, observable } from 'mobx';
import { applySnapshot, getRoot, getSnapshot, Model, model, modelAction, prop } from 'mobx-keystone';

import { IDashiProject, ProjectType } from '../models/Project';
import { reduceById, randomString } from '../util';


const ENV_PREFIX = process.env.REACT_APP_ENV === 'production' ? '' : `${process.env.REACT_APP_ENV}_`;

export interface IDashiPersistStore {
    id: string;
    exists: boolean;
    projects: IDashiProject[];
    list(): any;
    load(): void;
    newDocument(): void;
    remove(project: IDashiProject): void;
    delete(id: string): void;
    save(): void;
    seed(): void;
    description: string;
    addProject(project: IDashiProject): void;
    created: number;
    modified: number;
    createdBy: string;
    scenarioName: string;
    branch(): void;
    setId(id: string): void;
    setScenarioName(name: string): void;
    setDescription(description: string): void;
    getProject(id: string): IDashiProject|null;
};

@model('dashi/PersistStore')
class DashiPersistStore extends Model({
    id: prop<string>(() => randomString(20)).withSetter(),
    scenarioName: prop<string>('').withSetter(),
    description: prop<string>('').withSetter(),
    projects: prop<IDashiProject[]>(() => []),
    created: prop<number>(() => Date.now()).withSetter(),
    modified: prop<number>(0).withSetter(),
    createdBy: prop<string>(''),
}) implements IDashiPersistStore {

    private _db: any;
    private readonly _collection: string = `${ENV_PREFIX}scenarios`;

    @observable
    exists: boolean = false;

    onAttachedToRootStore() {
        const { config } = getRoot(this);

        if (firebase.apps.length === 0) {
            firebase.initializeApp(config);
        }

        this._db = firebase.firestore();

        this._db.collection(this._collection).doc(this.id).get().then((doc: any) => {
            this._setExists(doc.exists);
        });

        this.seed();

        window.addEventListener('keydown', e => {
            if (e.ctrlKey && e.key === 's' && this.exists) {
                e.preventDefault();
                e.stopPropagation();

                console.log('saving');
                this.save();
            }
        });
    }

    getProject(id: string) {
        return this._projectsById[id] || null;
    }

    branch() {
        this.setId(randomString(20));
        this.setCreated(Date.now());
    }

    async delete(id: string) {
        const doc = await this._db.collection(this._collection).doc(id).get();

        if (doc && doc.exists) {
            doc.ref.delete();
        }

        if (id === this.id) {
            this._setExists(false);
        }
    }

    async list() {
        const collection = await this._db.collection(this._collection).get();
        return collection.docs.map((doc: any) => doc.data()).sort((a: any, b: any) => a.created < b.created ? 1 : -1);
    }

    @modelAction
    load() {
        this._db.collection(this._collection).doc(this.id).get().then((doc: any) => {
            const data = doc.data();

            if (data) {
                data.$modelId = this.$modelId;
                applySnapshot(this, data);
                this._setExists(true);
            }
        });
    }

    newDocument() {
        const { app } = getRoot(this);

        this.branch();
        this.seed();
        app.triggerBlockSort();
        this.setScenarioName('');
        this.setDescription('');
        this._setExists(false);
    }

    @modelAction
    remove(project: IDashiProject) {
        if (project.mapped) {
            project.setType(ProjectType.Existing);
        }
        else {
            this.projects.splice(this.projects.indexOf(project), 1);
        }
    }

    seed() {
        console.info('No seed for this dashboard');
    }

    save() {
        if (!this.exists) {
            this._setCreatedBy(getRoot(this).user.displayName);
        }

        this.setModified(Date.now());
        console.log(getSnapshot(this));
        this._db.collection(this._collection).doc(this.id).set(getSnapshot(this));
        this._setExists(true);
    }

    @modelAction
    addProject(project: IDashiProject) {
        this.projects.push(project);
    }

    @modelAction
    private _setCreatedBy(createdBy: string) {
        this.createdBy = createdBy;
    }

    @modelAction
    private _setExists(exists: boolean) {
        this.exists = exists;
    }

    @computed
    private get _projectsById() {
        return reduceById<IDashiProject>(this.projects);
    }


}


export default DashiPersistStore;
