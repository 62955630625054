import { ExtendedModel, model } from 'mobx-keystone';

import DashiColorizerStore from '../../../core/stores/ColorizerStore';
import DashiColorizerCanvas from '../../../core/models/ColorizerCanvas';
import DashiColorizerAssets from '../../../core/models/ColorizerAssets';

import NBC_base from '../assets/img/NBC_Campus/Existing_Base.jpg';
import NBC_landscapeId from '../assets/img/NBC_Campus/landscape/id.png';
import NBC_landscapeKey from '../assets/img/NBC_Campus/landscape/id.png-keyIndex.json';
import NBC_landscapeGraphic from '../assets/img/NBC_Campus/landscape/graphic.jpg';
import NBC_buildingId from '../assets/img/NBC_Campus/building/output.png';
import NBC_buildingKey from '../assets/img/NBC_Campus/building/data.png-keyIndex.json';
import NBC_buildingGraphic from '../assets/img/NBC_Campus/building/data.png';

import East_base from '../assets/img/EastCampus/Existing_Base.jpg';
import East_landscapeId from '../assets/img/EastCampus/landscape/id.png';
import East_landscapeKey from '../assets/img/EastCampus/landscape/id.png-keyIndex.json';
import East_landscapeGraphic from '../assets/img/EastCampus/landscape/graphic.jpg';
import East_buildingId from '../assets/img/EastCampus/building/output.png';
import East_buildingKey from '../assets/img/EastCampus/building/data.png-keyIndex.json';
import East_buildingGraphic from '../assets/img/EastCampus/building/data.png';

import Main_base from '../assets/img/MainCampus/Existing_Base.jpg';
import Main_landscapeId from '../assets/img/MainCampus/landscape/id.png';
import Main_landscapeKey from '../assets/img/MainCampus/landscape/id.png-keyIndex.json';
import Main_landscapeGraphic from '../assets/img/MainCampus/landscape/graphic.jpg';
import Main_buildingId from '../assets/img/MainCampus/building/output.png';
import Main_buildingKey from '../assets/img/MainCampus/building/data.png-keyIndex.json';
import Main_buildingGraphic from '../assets/img/MainCampus/building/data.png';

import Will_base from '../assets/img/WillVill/Existing_Base.jpg';
import Will_landscapeId from '../assets/img/WillVill/landscape/id.png';
import Will_landscapeKey from '../assets/img/WillVill/landscape/id.png-keyIndex.json';
import Will_landscapeGraphic from '../assets/img/WillVill/landscape/graphic.jpg';
import Will_buildingId from '../assets/img/WillVill/building/output.png';
import Will_buildingKey from '../assets/img/WillVill/building/data.png-keyIndex.json';
import Will_buildingGraphic from '../assets/img/WillVill/building/data.png';


@model('demo/ColorizerStore')
class DemoColorizerStore extends ExtendedModel(DashiColorizerStore, {}) {

    onInit() {
        this.setStageSize(5200, 3000);

        this.addCanvas(new DashiColorizerCanvas({ 
            title: 'North Campus', 
            imageWidth: 2160, 
            imageHeight: 1147, 
            scale: 0.9,
            assets: new DashiColorizerAssets({
                base: NBC_base,
                landscape: {
                    id: NBC_landscapeId,
                    key: NBC_landscapeKey,
                    graphic: NBC_landscapeGraphic,
                },
                building: {
                    id: NBC_buildingId,
                    key: NBC_buildingKey,
                    graphic: NBC_buildingGraphic,
                }
            })
        }));

        this.addCanvas(new DashiColorizerCanvas({ 
            title: 'East Campus', 
            imageWidth: 2400, 
            imageHeight: 1482, 
            scale: 0.9,
            assets: new DashiColorizerAssets({
                base: East_base,
                landscape: {
                    id: East_landscapeId,
                    key: East_landscapeKey,
                    graphic: East_landscapeGraphic,
                },
                building: {
                    id: East_buildingId,
                    key: East_buildingKey,
                    graphic: East_buildingGraphic,
                }
            })
        }));

        this.addCanvas(new DashiColorizerCanvas({ 
            title: 'Main Campus', 
            imageWidth: 2401, 
            imageHeight: 1289, 
            scale: 1.05,
            assets: new DashiColorizerAssets({
                base: Main_base,
                landscape: {
                    id: Main_landscapeId,
                    key: Main_landscapeKey,
                    graphic: Main_landscapeGraphic,
                },
                building: {
                    id: Main_buildingId,
                    key: Main_buildingKey,
                    graphic: Main_buildingGraphic,
                }
            })
        }));

        this.addCanvas(new DashiColorizerCanvas({ 
            title: 'South Campus', 
            imageWidth: 2400, 
            imageHeight: 1419, 
            scale: 0.8,
            assets: new DashiColorizerAssets({
                base: Will_base,
                landscape: {
                    id: Will_landscapeId,
                    key: Will_landscapeKey,
                    graphic: Will_landscapeGraphic,
                },
                building: {
                    id: Will_buildingId,
                    key: Will_buildingKey,
                    graphic: Will_buildingGraphic,
                }
            })
        }));
    }

}


export default DemoColorizerStore;
