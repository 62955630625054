import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import Timeline from 'react-timeline';

import { useDashiStores } from '../..';
import SelectionInfo from "../SelectionInfo";
//import BedsChart from "../../charts/SpaceTypeChart";


export default observer(function LockedChartsPanel() {
    const { app, config } = useDashiStores();

    //const paddingPx = 8;
    const [content, setContent] = useState();
    const [size, setSize] = useState({width: 0, height: 0});

    useEffect(() => {
        if (content) {
            const boundingClientRect = content.getBoundingClientRect();
            if (boundingClientRect.width !== size.width || boundingClientRect.height !== size.height) {
                setSize({
                    width: boundingClientRect.width,
                    height: boundingClientRect.height,
                });
            }

        }
    }, [content, size.height, size.width]);

    const lockSpan = {
        left: 0,
        right: (config.totalPeriods + 1) * config.periodScale,
    };

    return (
        <div ref={setContent} className={`LockedChartPanel panel`}>
            <Timeline blocks={app.blocks} scrubber={app.scrubber} showNavigator={false} timelineLock={lockSpan} />
            <SelectionInfo />
            {/*size.width * size.height > 0 &&
                <BedsChart store={store} width={size.width - paddingPx * 2} height={size.height / 2}/>
            */}
        </div>
    );
});
