import { computed, observable } from 'mobx';
import { Block, time } from 'react-timeline';
import { getRoot, Model, model, modelAction, prop } from 'mobx-keystone';

import { randomString } from '../util';
import { IDashiMetric } from '../models/Metric';
import DashiProject, { IDashiProject, IProjectData, ProjectType } from '../models/Project';
import DashiPeriod, { IDashiPeriod } from '../models/Period';
import DashiUIStore, { IDashiUIStore } from './UIStore';
import RootStore, {IRootStore} from "./RootStore";
import DashiLayoutStore, {IDashiLayoutStore} from "./LayoutStore";


const MONTH = 10;


type MetricMap = {
    [key: string]: IDashiMetric;
}

export interface IDashiAppStore {
    ui: IDashiUIStore,
    layout: IDashiLayoutStore,
    colorMode: string,
    metrics: MetricMap;
    periods: IDashiPeriod[];
    projects: IDashiProject[];
    createProject(data: IProjectData): IDashiProject;
    createProjectFromBlock(block: typeof Block): IDashiProject;
    projectsKey: string;
    selectedProject: IDashiProject;
    selectedProjects: IDashiProject[],
    timelineLock: boolean;
    triggerBlockSort(): void;
    scrubber: number;
    setScrubber(position: number): void;
    rootStore: IRootStore;
    setTimelineLock(lock: boolean): void;
}

type FilterState = { [key: string]: string[] };


@model('dashi/AppStore')
class DashiAppStore extends Model({
    ui: prop<IDashiUIStore>(() => new DashiUIStore({})),
    layout: prop<IDashiLayoutStore>(() => new DashiLayoutStore({})),
    colorMode: prop<string>('type').withSetter(),
    metrics: prop<MetricMap>(() => ({})),
    timelineLock: prop<boolean>(false),
    periods: prop<IDashiPeriod[]>(() => []),
    scrubber: prop<number>(MONTH * time.MONTH),
}) implements IDashiAppStore {

    onAttachedToRootStore() {
        const {totalPeriods} = getRoot(this).config;

        for (let i = 1; i <= totalPeriods; i++) {
            this.periods.push(new DashiPeriod({i}));
        }

        this.triggerBlockSort();
    }

    @modelAction
    setScrubber(position: number) {
        this.scrubber = position;
    }

    @modelAction
    setTimelineLock(lock: boolean) {
        this.timelineLock = lock;
    }

    @computed
    get blocks() {
        const blocks: (typeof Block)[] = [];

        this.projects.forEach((project) => {
            const [block, designBlock] = project.blocks;

            blocks.push(block);
            blocks.push(designBlock);
        });

        return blocks;
    }

    @computed
    get currentPeriod() {
        const { periodScale } = getRoot(this).config;

        let period = Math.round(this.scrubber / periodScale);

        if (period < 0) {
            period = 0;
        }
        else if (period >= this.periods.length) {
            period = this.periods.length - 1;
        }

        return this.periods[period];
    }

    @observable
    filters: FilterState = {};

    @modelAction
    setFilters(filters: FilterState) {
        this.filters = filters;
        this.triggerBlockSort();
    }

    @computed
    get rootStore():IRootStore {
        return getRoot(this) as RootStore;
    }

    @computed
    /**
     *  @returns IDashiProject[] - only projects that pass the filter criteria.
     *  Use persist.projects to return all projects
     */
    get projects() {
        const { persist } = this.rootStore;

        return persist.projects.filter(project => {
            let filtered = true;

            if (project.type === ProjectType.Existing) {
                filtered = false;
            }
            else {
                Object.entries(this.filters).some(([attr, values]) => {
                    // @ts-ignore
                    if (values.indexOf(project[attr]) !== -1) {
                        filtered = false;
                    }
                    return !filtered;
                });
            }

            return filtered;
        });
    }

    createProject(data: IProjectData) {
        return new DashiProject(data);
    }

    createProjectFromBlock(block: typeof Block) {
        block.remove();

        return this.createProject({
            id: randomString(20),
            name: 'New Project',
            type: ProjectType.New,
            mapped: false,
            metrics: Object.keys(this.metrics).reduce((metrics: any, metric: string) => {
                metrics[metric] = this.metrics[metric].generatePayload()
                return metrics;
            }, {}),
            timelineY: block.y,
            timestamps: {
                design: block.start,
                start: block.start + (time.MONTH * 6),
                end: block.start + (time.MONTH * 18),
            }
        });
    }

    @computed
    get projectsKey() {
        return this.projects.map((project: IDashiProject )=> project.$modelId).reduce((a: string, b: string) => a + b, '');
    }

    @computed
    get selectedProjects() {
        return this.projects.filter((project: IDashiProject) => project.selected);
    }

    @computed
    get selectedProject() {
        return this.rootStore.persist.projects.filter((project: IDashiProject) => project.selected)[0];
    }

    @computed
    get periodLabels() {
        return this.periods.map(period => period.label);
    }

    triggerBlockSort() {
        const { config } = getRoot(this);

        [ ...this.projects ].sort((a,b) => this.sortBlocks(a,b)).forEach((project, i) => {
            project.setTimelineY(i * (config.timelineBlockHeight + config.timelineRowPadding));
        });
    }

    sortBlocks(a: IDashiProject, b: IDashiProject) {
        return a.timestamps.start === b.timestamps.start ? 0 : a.timestamps.start < b.timestamps.start ? -1 : 1;
    }

}


export default DashiAppStore;
