import { computed } from 'mobx';
import { time } from 'react-timeline';
import { ExtendedModel, model, modelAction, prop } from 'mobx-keystone';

import { getRootStore } from '../../../core';
import DashiProject, { IProjectData } from '../../../core/models/Project';

export interface IDemoProjectData extends IProjectData {
    funding: string;
}


@model('demo/Project')
class Project extends ExtendedModel(DashiProject, {
    funding: prop<string>(),
    gsf: prop<number>(65),
    escalation: prop<number>(4),
    escalationYear: prop<number>(2021),
}) {

    @modelAction
    setEscalation(escalation: number) {
        this.escalation = escalation;
    }

    @modelAction
    setEscalationYear(year: number) {
        this.escalationYear = year;
    }

    @modelAction
    setFunding(funding: string) {
        this.funding = funding;
    }

    @modelAction
    setGsf(gsf: number) {
        this.gsf = gsf;
    }

    @computed
    get compoundedEscalation() {
        const { startYear } = getRootStore().config;
        const years = Math.max(Math.floor(this.timestamps.start / time.YEAR) - (this.escalationYear - startYear), 0);
        
        let compounded = .025;
        for (let year = 0; year < years; year++) {
            const escalation = (year + this.escalationYear === startYear) ? .035 : this.escalation / 100;
            compounded = -1 + (1 + compounded) * (1 + escalation);
        }

        return compounded;
    }

    @computed
    get escalatedProjectCost() {
        return this.metrics['Cost'].cost * (1 + this.compoundedEscalation);
    }

    @computed
    get escalatedConstructionCost() {
        return this.metrics['Cost'].constructionCost * (1 + this.compoundedEscalation);
    }

    @computed
    get use() {
        let max = { value: -1, type: '' };

        const { before, after } = this.metrics['SpaceType'];
        [before, after].forEach(payload => {
            Object.entries(payload).forEach(([type, value]) => {
                if (value as number > max.value) {
                    max = { type, value: value as number };
                }
            });
        });

        return max.type;
    }

    @computed
    get asf() {
        return Math.max(this.metrics['SpaceType'].after[this.use] || 0, this.metrics['SpaceType'].before[this.use] || 0);
    }

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setCampus(snapshot.campus);
            this.setType(snapshot.type);
            this.setTimestamps(snapshot.timestamps);

            Object.keys(this.metrics).forEach((metric: string) => {
                this.setMetric(metric, snapshot.metrics[metric]);
            });

            this.setFunding(snapshot.funding);
            this.setGsf(snapshot.gsf);
        }
    }

}


export default Project;
