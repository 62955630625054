import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

import {useDashiStores} from '../../..';
import ColorizerStack from "./ColorizerStack";
import Toolbar from '../../ToolBar';
import canvasIcon from '../../assets/grid.svg'

const ZoomableStageContent = observer(function ({zoomIn, zoomOut, zoomToElement}) {
    let {colorizer, app} = useDashiStores();
    const {height, width, canvases} = colorizer;
    const {layout} = app;

    useEffect(() => {
        zoomToElement('All');
    }, []); // eslint-disable-line

    const calcPosition = (canvas, i) => {
        const midX = width * 0.55;
        const midY = height * 0.45;
        let col = i % 2;
        let row = Math.floor(i / 2);
        let x, y;
        if (col === 0) {
            x = midX - canvas.width;
        } else {
            x = midX;
        }
        if (row === 0) {

            y = midY - canvas.height;
        } else {
            y = midY;
        }
        return [x, y];
    }

    const extraWidth = width * layout.width / layout.colorizerWindow.size.width;
    const extraHeight = height * layout.height / layout.colorizerWindow.size.height;

    const zoomElements = ['All', ...canvases.map(c => c.title)]
    return <><Toolbar zoomIn={() => zoomIn()} zoomOut={() => zoomOut()} menuButtons={[
        {
            alt: 'zoom to canvas icon',
            icon: canvasIcon,
            items: zoomElements.map(name => ({
                name: name,
                onClick: () => zoomToElement(name)
            }))
        }
    ]}/>

        <TransformComponent wrapperClass='stage-wrapper' contentClass='stage-transform'>
            <div className="stage" style={{width, height}}>
                {canvases.map((canvas, i) => (
                    <ColorizerStack
                        key={canvas.title}
                        id={canvas.title}
                        canvas={canvas}
                        position={calcPosition(canvas, i)}
                        onButtonClick={() => zoomToElement(canvas.title)}
                    />
                ))}
            </div>
            <div id={'All'} className={'zoom-to-extra'} style={{width: extraWidth, height: extraHeight}}/>

        </TransformComponent>
    </>;
});

export default observer(function ColorizerStage() {
    return (
        <div className={'ColorizerStage'}>
            <TransformWrapper
                minScale={0.1}
                maxScale={1.5}
                initialScale={1}
                initialPositionX={200}
                initialPositionY={100}
                limitToBounds={false}
                velocityAnimation={{disabled: true}}
            >
                {({zoomIn, zoomOut, zoomToElement}) => <ZoomableStageContent
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    zoomToElement={zoomToElement}/>}
            </TransformWrapper>
        </div>
    );
});
