import {observer} from "mobx-react";
import {useDashiStores} from '../..';
import Timeline from 'react-timeline';

import Filters from '../Filters';
import RadioSlider from '../RadioSlider';
import SlidersIcon from '../icons/Sliders';
import TimeSlider from "../TimeSlider/TimeSlider";


export default observer(function TimelinePanel() {
    const { app, config, persist } = useDashiStores();
    const { ui, layout } = app;
    const { timelinePanel } = layout;
    const extended = ui.timelinePanelIsOpen && !ui.chartsPanelIsOpen;

    return (
        <div
            className={`TimelinePanel`}
            style={{
                width: timelinePanel.size.width,
                height: timelinePanel.size.height,
                left: timelinePanel.position.x,
                top: timelinePanel.position.y
            }}
        >
            <div className={`panel tPanel ${!ui.timelinePanelIsOpen ? 'panel--closed' : ''} ${extended ? 'panel--extended' : ''}`}>
                <div className="panel__header">
                    <div className="panel-toggle">
                        <button onClick={() => ui.setTimelinePanelOpen(!ui.timelinePanelIsOpen)}>
                            <SlidersIcon />
                        </button>

                        {ui.timelinePanelIsOpen && <h2>Timeline</h2>}
                    </div>

                    <TimeSlider/>

                    <div className="color-selector">
                        <RadioSlider
                            value={app.colorMode}
                            options={Object.keys(config.colorBy)}
                            onChange={mode => app.setColorMode(mode)}
                        />

                    </div>

                    <div className="timeline-menu">
                        <div className="menu-hamburger">
                            <div className="dot" />

                            <span>Menu</span>
                        </div>

                        <ul>
                            <li>
                                <button onClick={() => app.triggerBlockSort()}>Sort Blocks</button>
                            </li>
                        </ul>
                    </div>
                </div>

                {ui.timelinePanelIsOpen && <>
                    <Filters/>

                    <div className={"timelineHolder"}>
                        <Timeline
                            key={app.projectsKey}
                            blocks={app.blocks}
                            blockHeight={config.timelineBlockHeight}
                            editor={'LockedRowEditor'}
                            rowPadding={config.timelineRowPadding}
                            scrubber={app.scrubber}
                            defaultViewportWidth={(config.totalPeriods * .75) * config.periodScale}
                            timelineLock={false}
                            onBlockCreate={block => {
                                const project = app.createProjectFromBlock(block);
                                project.setSelected();
                                app.ui.setProjectSettingsOpen();

                                persist.addProject(project);
                            }}
                            onScrubberChange={scrubber => app.setScrubber(scrubber)}
                        />
                    </div>
                </>}
            </div>
        </div>
    );
});
