import { observer } from "mobx-react";

import { useDashiStores } from '../..';
import AnnotatedLineChart from "./AnnotatedLineChart";
import { formatDataSet, getCumulative } from "./chartFunctions";
import ChartHeader from "./ChartHeader/ChartHeader";


export default observer(function CostsChart({ style, title, width, height }) {
    const { app, config } = useDashiStores();
    const { ProjectFundingColors } = config;

    const data = {
        labels: app.periodLabels,
        datasets: [],
        axes: {x: {title: 'TIME'}, y: {title: 'ESC. TOTAL COST ($ mm)'}}
    };

    const periodData = getCumulative(app.metrics['Cost'].totalsByFundingType, 1000000, 2);

    if (periodData.length > 0) {
        Object.keys(periodData[0]).forEach((type, i) => {
            data.datasets.push(formatDataSet(ProjectFundingColors[type], type, periodData.map(types => types[type])));
        });
    }

    const totalCost = `$${Object.values(periodData[app.currentPeriod.i]).reduce((a,b) => a + b, 0).toLocaleString()} mm`;

    return (
        <div className="CostsChart" style={style}>
            <ChartHeader data={data} width={width} title = {title}  />
            <AnnotatedLineChart
                width={width}
                height={height}
                data={data}
                scrubberTime={{
                    value: app.scrubber / config.periodScale,
                    info: totalCost
                }}
            />
        </div>
    );
});
