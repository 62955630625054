import { observable } from 'mobx';
import { ExtendedModel, getRoot, model, modelAction } from 'mobx-keystone';
import NumberFormat from 'react-number-format';

import { IDashiProject, ProjectType } from '../models/Project';
import DashiMetric, { MetricBehavior } from '../models/Metric';


type SpaceTypeValues = {
    [key: string]: number;
};

type SpaceTypePayload = {
    before?: SpaceTypeValues;
    after?: SpaceTypeValues;
}

@model('dashi/SpaceTypeMetric')
class DashiSpaceTypeMetric extends ExtendedModel(DashiMetric, {}) {

    name = 'SpaceType';

    displayUnits(value: number) {
        return `${value} SF`;
    }

    generatePayload(x: SpaceTypePayload = {}) {
        const { SpaceTypeColors } = getRoot(this).config;
        const defaultPayload = { [Object.keys(SpaceTypeColors)[0]]: 0 };

        return {
            before: x.before || defaultPayload,
            after: x.after || defaultPayload,
        };
    }

    getValue = (behavior: MetricBehavior, payload: any, _: number) => {
        if (behavior === MetricBehavior.Before) {
            return payload.before; 
        }
        if (behavior === MetricBehavior.After) {
            return payload.after;
        }

        return {};
    }

    @observable
    options: string[] = [];

    @modelAction
    addOption(option: string) {
        this.options.push(option);
    }

    renderSettings(project: IDashiProject) {
        const { before, after } = project.metrics[this.name];

        return (
            <div className="SpaceTypeMetricSettings ProjectSettings-section">
                {project.type !== ProjectType.New && (
                    <div className="ProjectSettings-entry">
                        <label htmlFor="">Program Use</label>
                        <div className="ProjectSettings-input">
                            <div className="ProjectSettings-cell">
                                <label htmlFor="ExistingUse">Existing Use Removed</label>
                                <select 
                                    id="ExistingUse"
                                    value={Object.keys(before)[0]} 
                                    onChange={e => project.setMetric(this.name, { after, before: { [e.target.value]: Object.values(before)[0] }})}
                                >
                                    {this.options.map((option: string) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="ProjectSettings-cell">
                                <label htmlFor="ExistingASF">ASF</label>
                                <div className="labelled-input">
                                    <NumberFormat
                                        id="ExistingASF"
                                        thousandSeparator={true}
                                        // @ts-ignore
                                        value={Object.values(before)[0]}
                                        onValueChange={e => project.setMetric(this.name, { after, before: { [Object.keys(before)[0]]: e.floatValue }})}
                                    />
                                    <label htmlFor="ExistingASF">SF/FT</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {project.type !== ProjectType.Demo && (
                    <div className="ProjectSettings-entry">
                        <label htmlFor="">Program Use</label>
                        <div className="ProjectSettings-input">
                            <div className="ProjectSettings-cell">
                                <label htmlFor="NewUse">New Use Introduced</label>
                                <select 
                                    id="NewUse"
                                    value={Object.keys(after)[0]} 
                                    onChange={e => project.setMetric(this.name, { before, after: { [e.target.value]: Object.values(after)[0] }})}
                                >
                                    {this.options.map((option: string) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="ProjectSettings-cell">
                                <label htmlFor="NewASF">
                                    {project.type === ProjectType.Reno ? <>&nbsp;</> : 'ASF' }
                                </label>
                                <div className="labelled-input">
                                    <NumberFormat
                                        id="NewASF"
                                        thousandSeparator={true}
                                        // @ts-ignore
                                        value={Object.values(after)[0]}
                                        onValueChange={e => project.setMetric(this.name, { before, after: { [Object.keys(after)[0]]: e.floatValue }})}
                                    />
                                    <label htmlFor="NewASF">SF/FT</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


export default DashiSpaceTypeMetric;
