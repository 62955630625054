type NumberMap = {
    [key: string]: number;
};

export const getAnnotations = (scrubberTime: any, referenceLine: any, targetMarketColor?: string, chartTimeMarker?: string) => {
    const annotations:any[] = [];
    annotations.push({
        type: 'line',
        xMin: scrubberTime.value,
        xMax: scrubberTime.value,
        borderColor: chartTimeMarker,
        borderWidth: 2,
        label: {
            content: scrubberTime.info || '',
            enabled: true,
            position: "start"
        }
    });
    if (referenceLine) {
        annotations.push({
            type: "line",
            mode: "horizontal",
            borderDash: [2, 5],
            scaleID: "y-axis-0",
            borderColor: targetMarketColor,
            yMin: referenceLine.value,
            yMax: referenceLine.value,
            label: {
                content: referenceLine.info || '',
                enabled: true,
                position: "end"
            }
        })
    }
    return annotations;
};


export const formatDataSet = (color: string, dataSetName: string, data: number[]) => {
    return {
        label: dataSetName,
        fill: true,
        lineTension: 0.1,
        backgroundColor: color,
        borderColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data
    };
};
export const formatChartData = (color: string, labels: string[], dataSetName: string, data: number[], divisor:number = 1, rounding:number = 1) => {
    return {
        labels: labels,
        datasets: [formatDataSet(color, dataSetName, data.map(v => Math.round(rounding * v / divisor) / rounding))]
    };
};

export const getCumulative = (periods: NumberMap[], divisor: number = 1, precision: number = 0): NumberMap[] => {
    const totals: NumberMap[] = [];
    const cumulatives: NumberMap = {};

    periods.forEach(period => {
        const total: NumberMap = {};

        for (let key in period) {
            if (!(key in cumulatives)) {
                cumulatives[key] = 0;
            }

            cumulatives[key] += period[key];
            total[key] = parseFloat((cumulatives[key] / divisor).toFixed(precision));
        }
        totals.push(total);
    });

    return totals;
};
